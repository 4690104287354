// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TokenSetterSwap-module__defaultOptions___TSpCG{text-align:left}", "",{"version":3,"sources":["webpack://./src/pages/teleport/swap/components/TokenSetterSwap.module.scss"],"names":[],"mappings":"AAAA,gDACE,eAAA","sourcesContent":[".defaultOptions {\n  text-align: start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"defaultOptions": "TokenSetterSwap-module__defaultOptions___TSpCG"
};
export default ___CSS_LOADER_EXPORT___;
