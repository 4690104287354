// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DateTitle-module__date___g0zZY{text-align:center;-ms-flex-item-align:center;align-self:center;padding:10px;border-radius:2px 2px 0px 0px;margin:30px 0;position:relative;color:#fff;min-width:100px}.DateTitle-module__date___g0zZY::before{content:\"\";position:absolute;width:100%;height:80%;top:10%;left:0;-webkit-transform:scaleX(2.5);transform:scaleX(2.5);pointer-events:none;border-radius:50%;background:var(--primary-color);opacity:.2;-webkit-filter:blur(7px);filter:blur(7px)}", "",{"version":3,"sources":["webpack://./src/features/sense/ui/SenseViewer/Messages/DateTitle/DateTitle.module.scss"],"names":[],"mappings":"AAAA,gCACE,iBAAA,CACA,0BAAA,CAAA,iBAAA,CACA,YAAA,CACA,6BAAA,CACA,aAAA,CACA,iBAAA,CACA,UAAA,CAGA,eAAA,CAEA,wCACE,UAAA,CACA,iBAAA,CACA,UAAA,CACA,UAAA,CACA,OAAA,CACA,MAAA,CACA,6BAAA,CAAA,qBAAA,CACA,mBAAA,CACA,iBAAA,CAEA,+BAAA,CACA,UAAA,CAEA,wBAAA,CAAA,gBAAA","sourcesContent":[".date {\n  text-align: center;\n  align-self: center;\n  padding: 10px;\n  border-radius: 2px 2px 0px 0px;\n  margin: 30px 0;\n  position: relative;\n  color: white;\n\n  // for tooltip\n  min-width: 100px;\n\n  &::before {\n    content: '';\n    position: absolute;\n    width: 100%;\n    height: 80%;\n    top: 10%;\n    left: 0;\n    transform: scaleX(2.5);\n    pointer-events: none;\n    border-radius: 50%;\n\n    background: var(--primary-color);\n    opacity: 0.2;\n\n    filter: blur(7px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date": "DateTitle-module__date___g0zZY"
};
export default ___CSS_LOADER_EXPORT___;
