// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RankButton-module__rank___MB3yT svg{width:21px;height:21px}.RankButton-module__rank___MB3yT.RankButton-module__rankSelected___sNw0O svg,.RankButton-module__rank___MB3yT:hover svg{fill:#f62bfd}", "",{"version":3,"sources":["webpack://./src/components/search/Spark/LeftMeta/RankButton/RankButton.module.scss"],"names":[],"mappings":"AACE,qCACE,UAAA,CACA,WAAA,CAKA,wHACE,YAAA","sourcesContent":[".rank {\n  svg {\n    width: 21px;\n    height: 21px;\n  }\n\n  &.rankSelected,\n  &:hover {\n    svg {\n      fill: #f62bfd;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rank": "RankButton-module__rank___MB3yT",
	"rankSelected": "RankButton-module__rankSelected___sNw0O"
};
export default ___CSS_LOADER_EXPORT___;
