// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles__btnContainerBtnArrow___z2low{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border:none;cursor:pointer;background:rgba(0,0,0,0);position:relative}.styles__btnContainerBtnArrowImg___G3KSG{width:8px;height:8px;-o-object-fit:contain;object-fit:contain;-webkit-transition:.5s;transition:.5s}.styles__btnContainerBtnArrowImg___G3KSG:hover{opacity:.8}.styles__btnContainerBtnArrowImgOpen___fkXpI{-webkit-transform:rotate(90deg);transform:rotate(90deg)}", "",{"version":3,"sources":["webpack://./src/containers/sigma/components/cardUi/BtnArrow/styles.scss"],"names":[],"mappings":"AAAA,sCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,WAAA,CACA,cAAA,CACA,wBAAA,CACA,iBAAA,CAEA,yCACE,SAAA,CACA,UAAA,CACA,qBAAA,CAAA,kBAAA,CACA,sBAAA,CAAA,cAAA,CAEA,+CACE,UAAA,CAGF,6CACE,+BAAA,CAAA,uBAAA","sourcesContent":[".btnContainerBtnArrow {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border: none;\n  cursor: pointer;\n  background: transparent;\n  position: relative;\n\n  &Img {\n    width: 8px;\n    height: 8px;\n    object-fit: contain;\n    transition: 0.5s;\n\n    &:hover {\n      opacity: 0.8;\n    }\n\n    &Open {\n      transform: rotate(90deg);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnContainerBtnArrow": "styles__btnContainerBtnArrow___z2low",
	"btnContainerBtnArrowImg": "styles__btnContainerBtnArrowImg___G3KSG",
	"btnContainerBtnArrowImgOpen": "styles__btnContainerBtnArrowImgOpen___fkXpI"
};
export default ___CSS_LOADER_EXPORT___;
