// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".temp-module__color_red___iLoUY{color:var(--red)}.temp-module__color_green___jKP42{color:var(--green-light)}.temp-module__color_orange___bCLw5{color:orange}", "",{"version":3,"sources":["webpack://./src/features/cybernet/ui/pages/Subnet/tabs/Weights/WeightsTable/temp.module.scss"],"names":[],"mappings":"AACE,gCACE,gBAAA,CAEF,kCACE,wBAAA,CAEF,mCACE,YAAA","sourcesContent":[".color_ {\n  &red {\n    color: var(--red);\n  }\n  &green {\n    color: var(--green-light);\n  }\n  &orange {\n    color: orange;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"color_red": "temp-module__color_red___iLoUY",
	"color_green": "temp-module__color_green___jKP42",
	"color_orange": "temp-module__color_orange___bCLw5"
};
export default ___CSS_LOADER_EXPORT___;
