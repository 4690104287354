// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ValueImg-module__emojiIcon___ONyUg{color:#000;color:initial}", "",{"version":3,"sources":["webpack://./src/components/valueImg/ValueImg.module.scss"],"names":[],"mappings":"AAAA,oCACE,UAAA,CAAA,aAAA","sourcesContent":[".emojiIcon {\n  color: initial;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emojiIcon": "ValueImg-module__emojiIcon___ONyUg"
};
export default ___CSS_LOADER_EXPORT___;
