// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("images/share.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SubnetActionBar-module__installBtn___sdK3g{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:right 1.5px;background-size:20px;padding-right:25px}", "",{"version":3,"sources":["webpack://./src/features/cybernet/ui/pages/Subnet/SubnetActionBar/SubnetActionBar.module.scss","webpack://./src/style/mixins.scss"],"names":[],"mappings":"AAEA,4CCSE,wDAAA,CACA,2BAAA,CACA,+BAAA,CACA,oBAAA,CACA,kBAAA","sourcesContent":["@import '../../../../../../style/mixins.scss';\n\n.installBtn {\n  @include withShareIcon;\n}\n","@mixin tableHover {\n  background: linear-gradient(\n    90deg,\n    rgba(54, 214, 174, 0.15) -3.3%,\n    rgba(54, 214, 174, 0.072) 32.75%,\n    rgba(54, 214, 174, 0.0765) 62.88%,\n    rgba(54, 214, 174, 0.15) 100%\n  );\n}\n\n@mixin withShareIcon {\n  background-image: url('images/share.svg');\n  background-repeat: no-repeat;\n  background-position: right 1.5px;\n  background-size: 20px;\n  padding-right: 25px;\n}\n\n@mixin blueScroll {\n  &::-webkit-scrollbar-thumb {\n    background: var(--blue-light);\n  }\n  &::-webkit-scrollbar {\n    width: 2px;\n    height: 2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"installBtn": "SubnetActionBar-module__installBtn___sdK3g"
};
export default ___CSS_LOADER_EXPORT___;
