// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Electricity-module__electricity___pXVDq{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center;position:absolute;left:0;right:0;top:20px;opacity:.5;z-index:-1}.Electricity-module__electricity___pXVDq .Electricity-module__line___KpB6D{width:100%}.Electricity-module__electricity___pXVDq::before{content:\"\";position:absolute;background:#000;width:100%;height:100%;z-index:-1;top:0;left:0;bottom:0;opacity:.7}.Electricity-module__electricityLineRect___pafbg{fill:rgba(0,0,0,0) !important}", "",{"version":3,"sources":["webpack://./src/containers/home/Electricity.module.scss"],"names":[],"mappings":"AAAA,yCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAEA,iBAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,UAAA,CACA,UAAA,CAEA,2EACE,UAAA,CAGF,iDACE,UAAA,CACA,iBAAA,CACA,eAAA,CACA,UAAA,CACA,WAAA,CACA,UAAA,CACA,KAAA,CACA,MAAA,CACA,QAAA,CACA,UAAA,CASJ,iDACE,6BAAA","sourcesContent":[".electricity {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 20px;\n  opacity: 0.5;\n  z-index: -1;\n\n  .line {\n    width: 100%;\n  }\n\n  &::before {\n    content: '';\n    position: absolute;\n    background: #000;\n    width: 100%;\n    height: 100%;\n    z-index: -1;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    opacity: 0.7;\n  }\n}\n\n// .electricity-svg {\n//   stroke: #3ab793;\n//   stroke-width: 5;\n// }\n\n.electricityLineRect {\n  fill: rgba(0, 0, 0, 0) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"electricity": "Electricity-module__electricity___pXVDq",
	"line": "Electricity-module__line___KpB6D",
	"electricityLineRect": "Electricity-module__electricityLineRect___pafbg"
};
export default ___CSS_LOADER_EXPORT___;
