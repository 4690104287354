// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".proposalsDetail-module__containerDescription___58jOh p{margin-bottom:15px;text-align:justify}.proposalsDetail-module__containerDescription___58jOh h3{margin-bottom:20px}.proposalsDetail-module__containerDescription___58jOh h1{margin-bottom:20px}.proposalsDetail-module__containerDescription___58jOh ul{margin-bottom:15px;padding-left:20px}.proposalsDetail-module__containerDescription___58jOh ol{margin-bottom:15px;padding-left:20px}", "",{"version":3,"sources":["webpack://./src/containers/governance/proposalsDetail.module.scss"],"names":[],"mappings":"AACE,wDACE,kBAAA,CACA,kBAAA,CAGF,yDACE,kBAAA,CAGF,yDACE,kBAAA,CAGF,yDACE,kBAAA,CACA,iBAAA,CAGF,yDACE,kBAAA,CACA,iBAAA","sourcesContent":[".containerDescription {\n  p {\n    margin-bottom: 15px;\n    text-align: justify;\n  }\n\n  h3 {\n    margin-bottom: 20px;\n  }\n\n  h1 {\n    margin-bottom: 20px;\n  }\n\n  ul {\n    margin-bottom: 15px;\n    padding-left: 20px;\n  }\n\n  ol {\n    margin-bottom: 15px;\n    padding-left: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerDescription": "proposalsDetail-module__containerDescription___58jOh"
};
export default ___CSS_LOADER_EXPORT___;
