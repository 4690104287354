const STEP_INFO = {
  STATE_INIT: 1,
  STATE_INIT_NULL: 1.1,
  STATE_INIT_PROVE: 1.2,
  STATE_INIT_CLAIM: 1.3,
  STATE_INIT_RELEASE: 1.4,
  STATE_PROVE: 2,
  STATE_PROVE_CONNECT: 2.1,
  STATE_PROVE_SIGN_MM: 2.2,
  STATE_PROVE_SIGN_KEPLR: 2.3,
  STATE_PROVE_SEND_SIGN: 2.4,
  STATE_PROVE_IN_PROCESS: 2.5,
  STATE_PROVE_DONE: 2.6,
  STATE_GIFT_NULL: 2.7,
  STATE_PROVE_CHECK_ACCOUNT: 2.8,
  STATE_PROVE_CHANGE_ACCOUNT: 2.9,
  STATE_PROVE_YOU_ADDED_ADDR: 2.11,
  STATE_CLAIME: 3,
  STATE_GIFT_CLAIME: 3.1,
  STATE_CLAIME_ALL: 3.2,
  STATE_GIFT_NULL_ALL: 3.3,
  STATE_CLAIM_IN_PROCESS: 3.4,
  STATE_RELEASE: 3.5,
  STATE_CLAIME_TO_PROVE: 3.6,
  STATE_RELEASE_INIT: 4,
  STATE_RELEASE_ALL: 4.1,
  STATE_RELEASE_NULL: 4.2,
  STATE_RELEASE_IN_PROCESS: 4.3,
  STATE_DELETE_ADDRESS: 5,
  STATE_DELETE_IN_PROCESS: 5.1,
};

export default STEP_INFO;
