// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonIcon-module__container___UkmSU{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border:none;cursor:pointer;background:rgba(0,0,0,0);position:relative;margin:0 10px;padding:5px 0px}.ButtonIcon-module__container___UkmSU img{width:34px;height:30px;-o-object-fit:contain;object-fit:contain}.ButtonIcon-module__container___UkmSU.ButtonIcon-module__activeIcon___eF4Af::after{content:\"\";height:1px;width:20px;position:absolute;top:100%;background-color:#36d6ae;-webkit-box-shadow:0px 0px 3px 1px #36d6ae;box-shadow:0px 0px 3px 1px #36d6ae}.ButtonIcon-module__container___UkmSU:hover img{opacity:.8}.ButtonIcon-module__container___UkmSU:disabled{opacity:.6}", "",{"version":3,"sources":["webpack://./src/components/buttons/ButtonIcon/ButtonIcon.module.scss"],"names":[],"mappings":"AAAA,sCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,WAAA,CACA,cAAA,CACA,wBAAA,CACA,iBAAA,CACA,aAAA,CACA,eAAA,CAEA,0CACE,UAAA,CACA,WAAA,CACA,qBAAA,CAAA,kBAAA,CAIA,mFACE,UAAA,CACA,UAAA,CACA,UAAA,CACA,iBAAA,CACA,QAAA,CACA,wBAAA,CACA,0CAAA,CAAA,kCAAA,CAKF,gDACE,UAAA,CAIJ,+CACE,UAAA","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border: none;\n  cursor: pointer;\n  background: transparent;\n  position: relative;\n  margin: 0 10px;\n  padding: 5px 0px;\n\n  img {\n    width: 34px;\n    height: 30px;\n    object-fit: contain;\n  }\n\n  &.activeIcon {\n    &::after {\n      content: '';\n      height: 1px;\n      width: 20px;\n      position: absolute;\n      top: 100%;\n      background-color: #36d6ae;\n      box-shadow: 0px 0px 3px 1px #36d6ae;\n    }\n  }\n\n  &:hover {\n    img {\n      opacity: 0.8;\n    }\n  }\n\n  &:disabled {\n    opacity: 0.6;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ButtonIcon-module__container___UkmSU",
	"activeIcon": "ButtonIcon-module__activeIcon___eF4Af"
};
export default ___CSS_LOADER_EXPORT___;
