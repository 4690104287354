// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Vitalik-module__container___aY1NH{width:250px;height:250px;-webkit-box-shadow:0 0 10px 2px #03cba0;box-shadow:0 0 10px 2px #03cba0;border-radius:50%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-ms-flex-pack:distribute;justify-content:space-around;margin-bottom:10%}.Vitalik-module__container___aY1NH .Vitalik-module__oval1___ET3QQ{width:73px;height:37px;-webkit-transform:rotate(25deg);transform:rotate(25deg);-webkit-box-shadow:0 0 6px 2px #03cba0;box-shadow:0 0 6px 2px #03cba0;border-radius:50%;margin-bottom:20px}.Vitalik-module__container___aY1NH .Vitalik-module__oval2___vKo8c{width:73px;height:37px;-webkit-transform:rotate(-25deg);transform:rotate(-25deg);-webkit-box-shadow:0 0 6px 2px #03cba0;box-shadow:0 0 6px 2px #03cba0;border-radius:50%;margin-bottom:20px}", "",{"version":3,"sources":["webpack://./src/components/vitalik/Vitalik.module.scss"],"names":[],"mappings":"AAAA,mCACE,WAAA,CACA,YAAA,CACA,uCAAA,CAAA,+BAAA,CACA,iBAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,wBAAA,CAAA,4BAAA,CACA,iBAAA,CAEA,kEACE,UAAA,CACA,WAAA,CACA,+BAAA,CAAA,uBAAA,CACA,sCAAA,CAAA,8BAAA,CACA,iBAAA,CACA,kBAAA,CAGF,kEACE,UAAA,CACA,WAAA,CACA,gCAAA,CAAA,wBAAA,CACA,sCAAA,CAAA,8BAAA,CACA,iBAAA,CACA,kBAAA","sourcesContent":[".container {\n  width: 250px;\n  height: 250px;\n  box-shadow: 0 0 10px 2px #03cba0;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  margin-bottom: 10%;\n\n  .oval1 {\n    width: 73px;\n    height: 37px;\n    transform: rotate(25deg);\n    box-shadow: 0 0 6px 2px #03cba0;\n    border-radius: 50%;\n    margin-bottom: 20px;\n  }\n\n  .oval2 {\n    width: 73px;\n    height: 37px;\n    transform: rotate(-25deg);\n    box-shadow: 0 0 6px 2px #03cba0;\n    border-radius: 50%;\n    margin-bottom: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Vitalik-module__container___aY1NH",
	"oval1": "Vitalik-module__oval1___ET3QQ",
	"oval2": "Vitalik-module__oval2___vKo8c"
};
export default ___CSS_LOADER_EXPORT___;
