// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Validators-module__info___BkaAF>div{display:inline-block !important}", "",{"version":3,"sources":["webpack://./src/containers/Validators/Validators.module.scss"],"names":[],"mappings":"AACE,qCACE,+BAAA","sourcesContent":[".info {\n  > div {\n    display: inline-block !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": "Validators-module__info___BkaAF"
};
export default ___CSS_LOADER_EXPORT___;
