// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Movie-module__container___uLOnj{width:100vw;height:calc(100vh - 70px);position:fixed;top:70%}.Movie-module__containerLoading___LkwOA{width:100%;height:50vh;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.Movie-module__iframeCid___B131y{border:none}.Movie-module__iframeCid___B131y pre{color:#fff !important}", "",{"version":3,"sources":["webpack://./src/containers/movie/Movie.module.scss"],"names":[],"mappings":"AAAA,iCACE,WAAA,CACA,yBAAA,CACA,cAAA,CACA,OAAA,CAGF,wCACE,UAAA,CACA,WAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CAGF,iCACE,WAAA,CAEA,qCACE,qBAAA","sourcesContent":[".container {\n  width: 100vw;\n  height: calc(100vh - 70px);\n  position: fixed;\n  top: 70%;\n}\n\n.containerLoading {\n  width: 100%;\n  height: 50vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n\n.iframeCid {\n  border: none;\n\n  pre {\n    color: #fff !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Movie-module__container___uLOnj",
	"containerLoading": "Movie-module__containerLoading___LkwOA",
	"iframeCid": "Movie-module__iframeCid___B131y"
};
export default ___CSS_LOADER_EXPORT___;
