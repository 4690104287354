// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles__containerCodes___avjnG{display:grid;grid-gap:20px;width:60%;margin:0 auto}", "",{"version":3,"sources":["webpack://./src/containers/wasm/codes/styles.scss"],"names":[],"mappings":"AAAA,gCACE,YAAA,CAEA,aAAA,CACA,SAAA,CACA,aAAA","sourcesContent":[".containerCodes {\n  display: grid;\n  // grid-template-columns: repeat(auto-fill, minmax(calc(min(100%, 320px)), 1fr));\n  grid-gap: 20px;\n  width: 60%;\n  margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerCodes": "styles__containerCodes___avjnG"
};
export default ___CSS_LOADER_EXPORT___;
