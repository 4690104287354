// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("images/share.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Brain-module__wrapper___KeJdL{padding:0 50px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}.Brain-module__link___sSrF0 span{width:20px;height:20px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:right 1.5px;background-size:20px;padding-right:25px}", "",{"version":3,"sources":["webpack://./src/pages/Brain/Brain.module.scss","webpack://./src/style/mixins.scss"],"names":[],"mappings":"AAEA,+BACE,cAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CAIA,iCACE,UAAA,CACA,WAAA,CAAA,wDAAA,CCCF,2BAAA,CACA,+BAAA,CACA,oBAAA,CACA,kBAAA","sourcesContent":["@import '../../style/mixins.scss';\n\n.wrapper {\n  padding: 0 50px;\n  display: flex;\n  justify-content: center;\n}\n\n.link {\n  span {\n    width: 20px;\n    height: 20px;\n    @include withShareIcon;\n  }\n}\n","@mixin tableHover {\n  background: linear-gradient(\n    90deg,\n    rgba(54, 214, 174, 0.15) -3.3%,\n    rgba(54, 214, 174, 0.072) 32.75%,\n    rgba(54, 214, 174, 0.0765) 62.88%,\n    rgba(54, 214, 174, 0.15) 100%\n  );\n}\n\n@mixin withShareIcon {\n  background-image: url('images/share.svg');\n  background-repeat: no-repeat;\n  background-position: right 1.5px;\n  background-size: 20px;\n  padding-right: 25px;\n}\n\n@mixin blueScroll {\n  &::-webkit-scrollbar-thumb {\n    background: var(--blue-light);\n  }\n  &::-webkit-scrollbar {\n    width: 2px;\n    height: 2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Brain-module__wrapper___KeJdL",
	"link": "Brain-module__link___sSrF0"
};
export default ___CSS_LOADER_EXPORT___;
