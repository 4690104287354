// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./link.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Links-module__links___a9a5m{color:var(--blue-light);display:-webkit-box;display:-ms-flexbox;display:flex;gap:0 5px;white-space:nowrap}.Links-module__links___a9a5m span{display:inline-block;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;width:18px;height:21px;background-size:contain;position:relative;top:-2px}", "",{"version":3,"sources":["webpack://./src/components/search/Spark/Meta/Links/Links.module.scss"],"names":[],"mappings":"AAAA,6BACE,uBAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,SAAA,CAEA,kBAAA,CAEA,kCACE,oBAAA,CACA,wDAAA,CACA,2BAAA,CACA,UAAA,CACA,WAAA,CACA,uBAAA,CACA,iBAAA,CACA,QAAA","sourcesContent":[".links {\n  color: var(--blue-light);\n  display: flex;\n  gap: 0 5px;\n\n  white-space: nowrap;\n\n  span {\n    display: inline-block;\n    background-image: url('./link.svg');\n    background-repeat: no-repeat;\n    width: 18px;\n    height: 21px;\n    background-size: contain;\n    position: relative;\n    top: -2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"links": "Links-module__links___a9a5m"
};
export default ___CSS_LOADER_EXPORT___;
