// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Row-module__container___a8DnP{display:grid;grid-template-columns:240px 1fr;-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline;line-height:20px;font-size:16px}.Row-module__container___a8DnP .Row-module__value___QYuZo{text-transform:none !important}.Row-module__container___a8DnP .Row-module__value___QYuZo{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.Row-module__rowsContainer___UU1Ss{display:grid;grid-gap:10px;gap:10px}", "",{"version":3,"sources":["webpack://./src/components/Row/Row.module.scss"],"names":[],"mappings":"AAAA,+BACE,YAAA,CACA,+BAAA,CACA,0BAAA,CAAA,uBAAA,CAAA,oBAAA,CACA,gBAAA,CACA,cAAA,CAEA,0DACE,8BAIA,CALF,0DAEE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CAMJ,mCACE,YAAA,CACA,aAAA,CAAA,QAAA","sourcesContent":[".container {\n  display: grid;\n  grid-template-columns: 240px 1fr;\n  align-items: baseline;\n  line-height: 20px;\n  font-size: 16px;\n\n  .value {\n    text-transform: none !important;\n    display: flex;\n    justify-content: center;\n    align-items: flex-start;\n    flex-direction: column;\n  }\n}\n\n\n\n.rowsContainer {\n  display: grid;\n  gap: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Row-module__container___a8DnP",
	"value": "Row-module__value___QYuZo",
	"rowsContainer": "Row-module__rowsContainer___UU1Ss"
};
export default ___CSS_LOADER_EXPORT___;
