// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SwapItem-module__containerRoweDenom___nhKf0{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row}.SwapItem-module__containerRoweDenomReverse___NHRmL{-webkit-box-orient:horizontal;-webkit-box-direction:reverse;-ms-flex-direction:row-reverse;flex-direction:row-reverse}.SwapItem-module__containerSwapItem___NRP_T{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:10px}.SwapItem-module__denomsImgImg___RgvOO{width:25px}", "",{"version":3,"sources":["webpack://./src/pages/teleport/mainScreen/components/SwapAction/SwapItem.module.scss"],"names":[],"mappings":"AAAA,6CACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,6BAAA,CAAA,4BAAA,CAAA,sBAAA,CAAA,kBAAA,CAEA,oDACE,6BAAA,CAAA,6BAAA,CAAA,8BAAA,CAAA,0BAAA,CAIJ,4CACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,QAAA,CAGF,uCACE,UAAA","sourcesContent":[".containerRoweDenom {\n  display: flex;\n  flex-direction: row;\n\n  &Reverse {\n    flex-direction: row-reverse;\n  }\n}\n\n.containerSwapItem {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 10px;\n}\n\n.denomsImgImg {\n  width: 25px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerRoweDenom": "SwapItem-module__containerRoweDenom___nhKf0",
	"containerRoweDenomReverse": "SwapItem-module__containerRoweDenomReverse___NHRmL",
	"containerSwapItem": "SwapItem-module__containerSwapItem___NRP_T",
	"denomsImgImg": "SwapItem-module__denomsImgImg___RgvOO"
};
export default ___CSS_LOADER_EXPORT___;
