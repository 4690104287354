// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Delegate-module__nominators___WVgyz{margin:20px 0;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;gap:10px}.Delegate-module__nominatorsHeader___kzG4Z{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-flex:1;-ms-flex:1;flex:1}.Delegate-module__nominatorsHeader___kzG4Z h3{font-weight:400}.Delegate-module__list___olWFK{display:-webkit-box;display:-ms-flexbox;display:flex;gap:0 5px}", "",{"version":3,"sources":["webpack://./src/features/cybernet/ui/pages/Delegate/Delegate.module.scss"],"names":[],"mappings":"AAAA,qCACE,aAAA,CAEA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,QAAA,CAGF,2CACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,kBAAA,CAAA,UAAA,CAAA,MAAA,CAEA,8CACE,eAAA,CAIJ,+BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,SAAA","sourcesContent":[".nominators {\n  margin: 20px 0;\n\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.nominatorsHeader {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  flex: 1;\n\n  h3 {\n    font-weight: 400;\n  }\n}\n\n.list {\n  display: flex;\n  gap: 0 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nominators": "Delegate-module__nominators___WVgyz",
	"nominatorsHeader": "Delegate-module__nominatorsHeader___kzG4Z",
	"list": "Delegate-module__list___olWFK"
};
export default ___CSS_LOADER_EXPORT___;
