// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Containers-module__containerGridContainer___q9sSs{display:grid;grid-template-columns:1fr 1fr;grid-gap:20px;gap:20px}.Containers-module__containerCol___lp8zF{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;gap:20px}.Containers-module__teleportContainer___QPsQa{width:450px;margin:0 auto;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;gap:20px;font-size:16px}@media(max-width: 580px){.Containers-module__teleportContainer___QPsQa{width:100%}}", "",{"version":3,"sources":["webpack://./src/pages/teleport/components/containers/Containers.module.scss"],"names":[],"mappings":"AAAA,mDACE,YAAA,CACA,6BAAA,CACA,aAAA,CAAA,QAAA,CAGF,yCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,QAAA,CAGF,8CACE,WAAA,CACA,aAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,QAAA,CACA,cAAA,CAEA,yBARF,8CASI,UAAA,CAAA","sourcesContent":[".containerGridContainer {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 20px;\n}\n\n.containerCol {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n\n.teleportContainer {\n  width: 450px;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  font-size: 16px;\n\n  @media (width <=580px) {\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerGridContainer": "Containers-module__containerGridContainer___q9sSs",
	"containerCol": "Containers-module__containerCol___lp8zF",
	"teleportContainer": "Containers-module__teleportContainer___QPsQa"
};
export default ___CSS_LOADER_EXPORT___;
