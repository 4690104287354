// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".feeds-module__containerLogRows___QMGv8{display:grid;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}", "",{"version":3,"sources":["webpack://./src/pages/robot/_refactor/account/tabs/feeds.module.scss"],"names":[],"mappings":"AAAA,wCACE,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA","sourcesContent":[".containerLogRows {\n  display: grid;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerLogRows": "feeds-module__containerLogRows___QMGv8"
};
export default ___CSS_LOADER_EXPORT___;
