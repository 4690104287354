// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DataHistoryRow-module__containerDataIbc___DxSkb{display:grid;grid-gap:20px;gap:20px;color:#777;position:relative;overflow:hidden}.DataHistoryRow-module__containerDataIbcFlexBetween___mlgVD{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}.DataHistoryRow-module__containerDataIbcTimeStatus___kgAC2{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:10px}", "",{"version":3,"sources":["webpack://./src/pages/teleport/bridge/components/dataIbcHistory/DataHistoryRow.module.scss"],"names":[],"mappings":"AAAA,iDACE,YAAA,CACA,aAAA,CAAA,QAAA,CACA,UAAA,CACA,iBAAA,CACA,eAAA,CAEA,4DACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CAGF,2DACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,QAAA","sourcesContent":[".containerDataIbc {\n  display: grid;\n  gap: 20px;\n  color: #777777;\n  position: relative;\n  overflow: hidden;\n\n  &FlexBetween {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n  }\n\n  &TimeStatus {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerDataIbc": "DataHistoryRow-module__containerDataIbc___DxSkb",
	"containerDataIbcFlexBetween": "DataHistoryRow-module__containerDataIbcFlexBetween___mlgVD",
	"containerDataIbcTimeStatus": "DataHistoryRow-module__containerDataIbcTimeStatus___kgAC2"
};
export default ___CSS_LOADER_EXPORT___;
