// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LiquidBalances-module__wrapper___xmL1e{display:grid;-ms-flex-line-pack:space-evenly;align-content:space-evenly;height:100%}", "",{"version":3,"sources":["webpack://./src/containers/mint/LiquidBalances/LiquidBalances.module.scss"],"names":[],"mappings":"AAAA,wCACE,YAAA,CACA,+BAAA,CAAA,0BAAA,CACA,WAAA","sourcesContent":[".wrapper {\n  display: grid;\n  align-content: space-evenly;\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "LiquidBalances-module__wrapper___xmL1e"
};
export default ___CSS_LOADER_EXPORT___;
