// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Subnets-module__header___AyJbu{display:-webkit-box;display:-ms-flexbox;display:flex;gap:0 10px}", "",{"version":3,"sources":["webpack://./src/features/cybernet/ui/pages/Subnets/Subnets.module.scss"],"names":[],"mappings":"AAAA,gCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,UAAA","sourcesContent":[".header {\n  display: flex;\n  gap: 0 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "Subnets-module__header___AyJbu"
};
export default ___CSS_LOADER_EXPORT___;
