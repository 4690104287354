// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UnderConstruction-module__wrapper___ls9n_{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;text-align:center;height:100%}.UnderConstruction-module__wrapper___ls9n_ img{width:350px}.UnderConstruction-module__wrapper___ls9n_ h5{font-size:30px;color:rgba(255,255,255,.78);text-shadow:0px 0px 10px var(--blue-light)}", "",{"version":3,"sources":["webpack://./src/pages/robot/UnderConstruction/UnderConstruction.module.scss"],"names":[],"mappings":"AAAA,2CACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,iBAAA,CACA,WAAA,CAEA,+CACE,WAAA,CAGF,8CACE,cAAA,CACA,2BAAA,CAEA,0CAAA","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  height: 100%;\n\n  img {\n    width: 350px;\n  }\n\n  h5 {\n    font-size: 30px;\n    color: rgba(255, 255, 255, 0.78);\n\n    text-shadow: 0px 0px 10px var(--blue-light);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "UnderConstruction-module__wrapper___ls9n_"
};
export default ___CSS_LOADER_EXPORT___;
