import Sigma from '.';

function SigmaWrapper() {
  return (
    <div
      style={{
        margin: '0 auto',
        width: '72%',
        paddingBottom: '130px',
      }}
    >
      <Sigma />
    </div>
  );
}

export default SigmaWrapper;
