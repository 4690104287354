// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Keys-module__wrapper___MViKR{display:grid;-ms-flex-line-pack:start;align-content:flex-start;width:100%;grid-gap:20px;gap:20px;min-height:70vh}.Keys-module__wrapper___MViKR>p{margin:auto;position:relative;top:65px;text-align:center;line-height:26px}", "",{"version":3,"sources":["webpack://./src/pages/Keys/Keys.module.scss"],"names":[],"mappings":"AAAA,8BACE,YAAA,CACA,wBAAA,CAAA,wBAAA,CACA,UAAA,CACA,aAAA,CAAA,QAAA,CACA,eAAA,CAEA,gCACE,WAAA,CACA,iBAAA,CACA,QAAA,CACA,iBAAA,CACA,gBAAA","sourcesContent":[".wrapper {\n  display: grid;\n  align-content: flex-start;\n  width: 100%;\n  gap: 20px;\n  min-height: 70vh;\n\n  >p {\n    margin: auto;\n    position: relative;\n    top: 65px;\n    text-align: center;\n    line-height: 26px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Keys-module__wrapper___MViKR"
};
export default ___CSS_LOADER_EXPORT___;
