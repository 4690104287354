// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WeightsTable-module__wrapper___DEMtJ{display:-webkit-box;display:-ms-flexbox;display:flex}.WeightsTable-module__wrapper___DEMtJ table:nth-of-type(1) tr:hover{background:rgba(0,0,0,0)}.WeightsTable-module__wrapper___DEMtJ table th{height:85px}.WeightsTable-module__headerCell___O94eQ{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}", "",{"version":3,"sources":["webpack://./src/features/cybernet/ui/pages/Subnet/tabs/Weights/WeightsTable/WeightsTable.module.scss"],"names":[],"mappings":"AAAA,sCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CAII,oEACE,wBAAA,CAKN,+CACE,WAAA,CAIJ,yCACE,iBAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA","sourcesContent":[".wrapper {\n  display: flex;\n\n  table:nth-of-type(1) {\n    tr {\n      &:hover {\n        background: transparent;\n      }\n    }\n  }\n\n  table th {\n    height: 85px;\n  }\n}\n\n.headerCell {\n  position: relative;\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "WeightsTable-module__wrapper___DEMtJ",
	"headerCell": "WeightsTable-module__headerCell___O94eQ"
};
export default ___CSS_LOADER_EXPORT___;
