// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TitleAction-module__container___VCqS9{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;gap:6px}.TitleAction-module__container___VCqS9:hover .TitleAction-module__title___HqbdZ{color:#00ffbf;text-shadow:0px 0px 5px var(--primary-color)}.TitleAction-module__title___HqbdZ{color:var(--primary-color);font-size:20px;font-weight:400}.TitleAction-module__subTitle___DWnRR{color:rgba(255,255,255,.5019607843);font-size:14px;line-height:19px}", "",{"version":3,"sources":["webpack://./src/pages/teleport/mainScreen/components/TitleAction/TitleAction.module.scss"],"names":[],"mappings":"AAAA,uCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,OAAA,CAGE,gFACE,aAAA,CACA,4CAAA,CAKN,mCACE,0BAAA,CACA,cAAA,CACA,eAAA,CAIF,sCACE,mCAAA,CACA,cAAA,CACA,gBAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 6px;\n\n  &:hover {\n    .title {\n      color: #00FFBF;\n      text-shadow: 0px 0px 5px var(--primary-color);\n    }\n  }\n}\n\n.title {\n  color: var(--primary-color);\n  font-size: 20px;\n  font-weight: 400;\n\n}\n\n.subTitle {\n  color: #ffffff80;\n  font-size: 14px;\n  line-height: 19px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TitleAction-module__container___VCqS9",
	"title": "TitleAction-module__title___HqbdZ",
	"subTitle": "TitleAction-module__subTitle___DWnRR"
};
export default ___CSS_LOADER_EXPORT___;
