// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InputMemo-module__containerInputMemo___R4dcn{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;position:relative;width:100%}.InputMemo-module__containerAddFileButton___A9bSV{position:absolute;right:10px;bottom:30px}.InputMemo-module__containerAddFileButton___A9bSV input{display:none}.InputMemo-module__valueInputMemo____8Msx{padding:10px 35px 10px 10px !important}", "",{"version":3,"sources":["webpack://./src/pages/teleport/components/Inputs/InputMemo/InputMemo.module.scss"],"names":[],"mappings":"AAAA,8CACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,iBAAA,CACA,UAAA,CAGF,kDACE,iBAAA,CACA,UAAA,CACA,WAAA,CAEA,wDACE,YAAA,CAIJ,0CACE,sCAAA","sourcesContent":[".containerInputMemo {\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  width: 100%;\n}\n\n.containerAddFileButton {\n  position: absolute;\n  right: 10px;\n  bottom: 30px;\n\n  input {\n    display: none;\n  }\n}\n\n.valueInputMemo {\n  padding: 10px 35px 10px 10px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerInputMemo": "InputMemo-module__containerInputMemo___R4dcn",
	"containerAddFileButton": "InputMemo-module__containerAddFileButton___A9bSV",
	"valueInputMemo": "InputMemo-module__valueInputMemo____8Msx"
};
export default ___CSS_LOADER_EXPORT___;
