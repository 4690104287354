// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DataSendTxs-module__containerMemo___iBgqF{color:#fff;text-align:right}.DataSendTxs-module__containerMemoStart___kYu8b{text-align:left}.DataSendTxs-module__containerAmountTime___MhMM4{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}.DataSendTxs-module__amountDenomContainer___LJeww{color:var(--green-light)}.DataSendTxs-module__amountDenomContainerRed____xhRu{color:var(--red)}", "",{"version":3,"sources":["webpack://./src/pages/teleport/send/components/dataSendTxs/DataSendTxs.module.scss"],"names":[],"mappings":"AAAA,2CACE,UAAA,CACA,gBAAA,CAEA,gDACE,eAAA,CAIJ,iDACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,0BAAA,CAAA,uBAAA,CAAA,oBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CAGF,kDACE,wBAAA,CAEA,qDACE,gBAAA","sourcesContent":[".containerMemo {\n  color: #fff;\n  text-align: end;\n\n  &Start {\n    text-align: start;\n  }\n}\n\n.containerAmountTime {\n  display: flex;\n  align-items: baseline;\n  justify-content: space-between;\n}\n\n.amountDenomContainer {\n  color: var(--green-light);\n\n  &Red {\n    color: var(--red);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerMemo": "DataSendTxs-module__containerMemo___iBgqF",
	"containerMemoStart": "DataSendTxs-module__containerMemoStart___kYu8b",
	"containerAmountTime": "DataSendTxs-module__containerAmountTime___MhMM4",
	"amountDenomContainer": "DataSendTxs-module__amountDenomContainer___LJeww",
	"amountDenomContainerRed": "DataSendTxs-module__amountDenomContainerRed____xhRu"
};
export default ___CSS_LOADER_EXPORT___;
