// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tabs-module__wrapper___V7Efq{display:grid;grid-template-columns:repeat(auto-fit, minmax(110px, 1fr));width:100%;grid-row-gap:15px;row-gap:15px}", "",{"version":3,"sources":["webpack://./src/components/Tabs/Tabs.module.scss"],"names":[],"mappings":"AAAA,8BACE,YAAA,CACA,0DAAA,CACA,UAAA,CACA,iBAAA,CAAA,YAAA","sourcesContent":[".wrapper {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));\n  width: 100%;\n  row-gap: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Tabs-module__wrapper___V7Efq"
};
export default ___CSS_LOADER_EXPORT___;
