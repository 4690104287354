// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreatedAt-module__createdAt___FXzuC{color:#777}", "",{"version":3,"sources":["webpack://./src/components/CreatedAt/CreatedAt.module.scss"],"names":[],"mappings":"AAAA,qCACE,UAAA","sourcesContent":[".createdAt {\n  color: #777;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"createdAt": "CreatedAt-module__createdAt___FXzuC"
};
export default ___CSS_LOADER_EXPORT___;
