// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TokenChange-module__wrapper___PN8g8{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:30px;font-size:14px;white-space:nowrap}.TokenChange-module__change___S7lky{color:red}.TokenChange-module__change___S7lky.TokenChange-module__increase___cxnMf{color:var(--green-2)}.TokenChange-module__change___S7lky.TokenChange-module__increase___cxnMf::before{content:\"+\"}", "",{"version":3,"sources":["webpack://./src/components/TokenChange/TokenChange.module.scss"],"names":[],"mappings":"AAAA,qCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,QAAA,CACA,cAAA,CACA,kBAAA,CAGF,oCACE,SAAA,CAEA,yEACE,oBAAA,CAEA,iFACE,WAAA","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: center;\n  gap: 30px;\n  font-size: 14px;\n  white-space: nowrap;\n}\n\n.change {\n  color: #ff0000;\n\n  &.increase {\n    color: var(--green-2);\n\n    &::before {\n      content: '+';\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "TokenChange-module__wrapper___PN8g8",
	"change": "TokenChange-module__change___S7lky",
	"increase": "TokenChange-module__increase___cxnMf"
};
export default ___CSS_LOADER_EXPORT___;
