// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles__containerRowItem___vZLnL{display:grid;grid-template-columns:110px 1fr .7fr 1fr;height:50px}.styles__containerColItem___SXHzH{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline}", "",{"version":3,"sources":["webpack://./src/containers/nebula/styles.scss"],"names":[],"mappings":"AAAA,kCACE,YAAA,CACA,wCAAA,CACA,WAAA,CAGF,kCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,0BAAA,CAAA,uBAAA,CAAA,oBAAA","sourcesContent":[".containerRowItem {\n  display: grid;\n  grid-template-columns: 110px 1fr 0.7fr 1fr;\n  height: 50px;\n}\n\n.containerColItem {\n  display: flex;\n  align-items: baseline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerRowItem": "styles__containerRowItem___vZLnL",
	"containerColItem": "styles__containerColItem___SXHzH"
};
export default ___CSS_LOADER_EXPORT___;
