// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".steles__gatewayCantainer___pfedc{text-align:center;background-color:#000;padding-bottom:5px;height:1px;width:100%;min-height:calc(100vh - 70px)}", "",{"version":3,"sources":["webpack://./src/components/contentIpfs/component/gateway/steles.scss"],"names":[],"mappings":"AAAA,kCACE,iBAAA,CACA,qBAAA,CACA,kBAAA,CACA,UAAA,CACA,UAAA,CACA,6BAAA","sourcesContent":[".gatewayCantainer {\n  text-align: center;\n  background-color: #000;\n  padding-bottom: 5px;\n  height: 1px;\n  width: 100%;\n  min-height: calc(100vh - 70px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gatewayCantainer": "steles__gatewayCantainer___pfedc"
};
export default ___CSS_LOADER_EXPORT___;
