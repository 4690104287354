// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Layout-module__wrapper___avaC0{display:grid;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;grid-template-columns:1fr minmax(200px, 62%) 1fr;padding-bottom:102px}.Layout-module__wrapper___avaC0>main{min-height:100%;display:grid}.Layout-module__wrapper___avaC0>main>header{-ms-flex-item-align:start;align-self:flex-start}", "",{"version":3,"sources":["webpack://./src/pages/robot/Layout/Layout.module.scss","webpack://./src/layouts/variables.module.scss"],"names":[],"mappings":"AAEA,gCACE,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,gDAAA,CAGA,oBCFgB,CDIhB,qCAEE,eAAA,CAGA,YAAA,CAEA,4CAEE,yBAAA,CAAA,qBAAA","sourcesContent":["@import '../../../layouts//variables.module.scss';\n\n.wrapper {\n  display: grid;\n  justify-content: center;\n  align-items: flex-start;\n  grid-template-columns: 1fr minmax(200px, 62%) 1fr;\n\n  // padding for action bar, if scroll bottom\n  padding-bottom: $actionBarHeight;\n\n  > main {\n    // same with menu\n    min-height: 100%;\n\n    // stretch children by height\n    display: grid;\n\n    > header {\n      // because of grid\n      align-self: flex-start;\n    }\n  }\n}\n","$headerHeight: 105px;\n\n$adviserHeight: 60px;\n$adviserOffset: 15px;\n\n$reservedTopHeight: $headerHeight + $adviserHeight - $adviserOffset;\n\n$actionBarHeight: 102px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Layout-module__wrapper___avaC0"
};
export default ___CSS_LOADER_EXPORT___;
