// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BridgeAction-module__container___LjofT{display:grid;grid-template-columns:repeat(3, 1fr);overflow:hidden;padding:5px}", "",{"version":3,"sources":["webpack://./src/pages/teleport/mainScreen/components/BridgeAction/BridgeAction.module.scss"],"names":[],"mappings":"AAAA,wCACE,YAAA,CACA,oCAAA,CACA,eAAA,CACA,WAAA","sourcesContent":[".container {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  overflow: hidden;\n  padding: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "BridgeAction-module__container___LjofT"
};
export default ___CSS_LOADER_EXPORT___;
