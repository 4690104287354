// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".stylesQueryContract__containerQueryContract___QjyHF{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;grid-gap:20px;margin-bottom:20px}.stylesQueryContract__containerQueryContractMessage___HFpy2{-webkit-box-shadow:#36d6ae 0px 0px 5px;box-shadow:#36d6ae 0px 0px 5px;border-radius:5px;padding:15px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;grid-gap:20px}", "",{"version":3,"sources":["webpack://./src/containers/wasm/contract/stylesQueryContract.scss","webpack://./src/containers/wasm/globalStyle.scss"],"names":[],"mappings":"AAEA,qDCDE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,aAAA,CDCA,kBAAA,CAEA,4DCCA,sCAAA,CAAA,8BAAA,CACA,iBAAA,CACA,YAAA,CARA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,aAAA","sourcesContent":["@import '../globalStyle.scss';\n\n.containerQueryContract {\n  @include displayFlexGap;\n  margin-bottom: 20px;\n\n  &Message {\n    @include border-card;\n    @include displayFlexGap;\n  }\n}\n","@mixin displayFlexGap {\n  display: flex;\n  flex-direction: column;\n  grid-gap: 20px;\n}\n\n@mixin border-card {\n  box-shadow: #36d6ae 0px 0px 5px;\n  border-radius: 5px;\n  padding: 15px;\n}\n\n@mixin ItemInfoValue {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerQueryContract": "stylesQueryContract__containerQueryContract___QjyHF",
	"containerQueryContractMessage": "stylesQueryContract__containerQueryContractMessage___HFpy2"
};
export default ___CSS_LOADER_EXPORT___;
