const steps = {
  STEP_INIT: 0,
  STEP_NICKNAME_CHOSE: 1,
  STEP_NICKNAME_APROVE: 1.1,
  STEP_NICKNAME_INVALID: 1.2,
  STEP_AVATAR_UPLOAD: 2,
  STEP_KEPLR_INIT: 3,
  STEP_KEPLR_INIT_CHECK_FNC: 3.1,
  STEP_KEPLR_INIT_INSTALLED: 3.2,
  STEP_KEPLR_SETUP: 4,
  STEP_KEPLR_CONNECT: 4.1,
  STEP_CHECK_ADDRESS: 5,
  STEP_CHECK_ADDRESS_CHECK_FNC: 5.1,
  STEP_ACTIVE_ADD: 5.2,
  STEP_RULES: 6,
  STEP_KEPLR_REGISTER: 7,
  STEP_DONE: 7.1,
  STEP_CHECK_GIFT: 11,
};

// eslint-disable-next-line import/prefer-default-export
export { steps };
