// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InfoText-module__text___xPElt{text-align:center;grid-area:2/2/2/2;font-size:16px;position:absolute;bottom:30px;color:var(--grayscale-dark)}.InfoText-module__text___xPElt strong{color:#fff;font-weight:400;white-space:nowrap}.InfoText-module__text___xPElt>div{display:inline-block !important}", "",{"version":3,"sources":["webpack://./src/containers/mint/InfoText/InfoText.module.scss"],"names":[],"mappings":"AAAA,+BACE,iBAAA,CACA,iBAAA,CACA,cAAA,CACA,iBAAA,CACA,WAAA,CAEA,2BAAA,CAEA,sCACE,UAAA,CACA,eAAA,CACA,kBAAA,CAGF,mCACE,+BAAA","sourcesContent":[".text {\n  text-align: center;\n  grid-area: 2/2/2/2;\n  font-size: 16px;\n  position: absolute;\n  bottom: 30px;\n\n  color: var(--grayscale-dark);\n\n  strong {\n    color: white;\n    font-weight: 400;\n    white-space: nowrap;\n  }\n\n  >div {\n    display: inline-block !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "InfoText-module__text___xPElt"
};
export default ___CSS_LOADER_EXPORT___;
