// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__containerContent___lUklP{width:62%;margin:0px auto;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;gap:20px 0}@media(max-width: 650px){.styles-module__containerContent___lUklP{width:82%}}@media(max-width: 540px){.styles-module__containerContent___lUklP{width:100% !important}}.styles-module__noMaxWidth___aVQkC{max-width:none;max-width:initial}", "",{"version":3,"sources":["webpack://./src/components/MainContainer/styles.module.scss"],"names":[],"mappings":"AAAA,yCACE,SAAA,CACA,eAAA,CAEA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,UAAA,CAEA,yBARF,yCASI,SAAA,CAAA,CAGF,yBAZF,yCAaI,qBAAA,CAAA,CAIJ,mCACE,cAAA,CAAA,iBAAA","sourcesContent":[".containerContent {\n  width: 62%;\n  margin: 0px auto;\n\n  display: flex;\n  flex-direction: column;\n  gap: 20px 0;\n\n  @media (max-width: 650px) {\n    width: 82%;\n  }\n\n  @media (max-width: 540px) {\n    width: 100% !important;\n  }\n}\n\n.noMaxWidth {\n  max-width: unset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerContent": "styles-module__containerContent___lUklP",
	"noMaxWidth": "styles-module__noMaxWidth___aVQkC"
};
export default ___CSS_LOADER_EXPORT___;
