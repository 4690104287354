// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Message-container-module__follow___SL5uY{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:5px 10px;-ms-flex-wrap:wrap;flex-wrap:wrap}.Message-container-module__follow___SL5uY>span:nth-of-type(1){position:relative;top:1px}", "",{"version":3,"sources":["webpack://./src/features/sense/ui/SenseViewer/Messages/Message/Message.container.module.scss"],"names":[],"mappings":"AAAA,0CACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,YAAA,CACA,kBAAA,CAAA,cAAA,CAEA,8DACE,iBAAA,CACA,OAAA","sourcesContent":[".follow {\n  display: flex;\n  align-items: center;\n  gap: 5px 10px;\n  flex-wrap: wrap;\n\n  > span:nth-of-type(1) {\n    position: relative;\n    top: 1px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"follow": "Message-container-module__follow___SL5uY"
};
export default ___CSS_LOADER_EXPORT___;
