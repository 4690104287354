// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__column___CDM5a{display:grid;justify-items:center;grid-template-columns:repeat(auto-fit, minmax(200px, 1fr));grid-gap:20px}.styles-module__column___CDM5a .styles-module__columnItem___FJGP8{display:grid;width:100%;grid-gap:20px;gap:20px;grid-template-columns:100%;gap:20px;grid-auto-rows:-webkit-max-content;grid-auto-rows:max-content;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;padding-top:10px;padding-bottom:15px;border-radius:5px}", "",{"version":3,"sources":["webpack://./src/containers/governance/components/styles.module.scss"],"names":[],"mappings":"AAAA,+BACE,YAAA,CACA,oBAAA,CACA,0DAAA,CACA,aAAA,CAEA,kEACE,YAAA,CACA,UAAA,CACA,aAAA,CAAA,QAAA,CACA,0BAAA,CACA,QAAA,CACA,kCAAA,CAAA,0BAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,gBAAA,CACA,mBAAA,CACA,iBAAA","sourcesContent":[".column {\n  display: grid;\n  justify-items: center;\n  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));\n  grid-gap: 20px;\n\n  .columnItem {\n    display: grid;\n    width: 100%;\n    gap: 20px;\n    grid-template-columns: 100%;\n    gap: 20px;\n    grid-auto-rows: max-content;\n    align-items: flex-start;\n    padding-top: 10px;\n    padding-bottom: 15px;\n    border-radius: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"column": "styles-module__column___CDM5a",
	"columnItem": "styles-module__columnItem___FJGP8"
};
export default ___CSS_LOADER_EXPORT___;
