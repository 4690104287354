// export const CID_AVATAR = 'Qmf89bXkJH9jw4uaLkHmZkxQ51qGKfUPtAMxA8rTwBrmTs';
export const CID_TWEET = 'QmbdH2WBamyKLPE5zu4mJ9v49qvY8BFfoumoVPMR5V4Rvx';

export const CID_FOLLOW = 'QmPLSA5oPqYxgc8F7EwrM8WS9vKrr1zPoDniSRFh8HSrxx';

export const INFINITY = '∞';

export const WP =
  'https://ipfs.io/ipfs/QmQ1Vong13MDNxixDyUdjniqqEj8sjuNEBYMyhQU4gQgq3';

export const CYBER_CONGRESS_ADDRESS =
  'bostrom1xszmhkfjs3s00z2nvtn7evqxw3dtus6yr8e4pw';
