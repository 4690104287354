// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LogRelayer-module__containerMessageItem___z1Y4E{background-color:rgba(3,203,160,.1607843137);border-radius:5px;padding:5px;margin:5px;width:70%}.LogRelayer-module__containerMessageItem___z1Y4E span{white-space:pre-wrap}.LogRelayer-module__containerLogRelayer___WeJMD{width:720px;height:400px;border:1px solid #000;text-align:left;padding:5px}", "",{"version":3,"sources":["webpack://./src/pages/teleport/relayer/components/Relayer/LogRelayer.module.scss"],"names":[],"mappings":"AAAA,iDACE,4CAAA,CACA,iBAAA,CACA,WAAA,CACA,UAAA,CACA,SAAA,CAEA,sDACE,oBAAA,CAIJ,gDACE,WAAA,CACA,YAAA,CACA,qBAAA,CACA,eAAA,CACA,WAAA","sourcesContent":[".containerMessageItem {\n  background-color: #03cba029;\n  border-radius: 5px;\n  padding: 5px;\n  margin: 5px;\n  width: 70%;\n\n  span {\n    white-space: pre-wrap;\n  }\n}\n\n.containerLogRelayer {\n  width: 720px;\n  height: 400px;\n  border: 1px solid black;\n  text-align: left;\n  padding: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerMessageItem": "LogRelayer-module__containerMessageItem___z1Y4E",
	"containerLogRelayer": "LogRelayer-module__containerLogRelayer___WeJMD"
};
export default ___CSS_LOADER_EXPORT___;
