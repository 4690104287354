// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./images/attach.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./images/close.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddFile-module__button___VDTMn{background-color:rgba(0,0,0,0);outline:none;border:medium none currentColor;border:initial;background-position:center center;background-repeat:no-repeat;height:25px;width:25px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");margin:0 5px;background-size:contain;font-size:0;padding:3px}.AddFile-module__button___VDTMn.AddFile-module__remove___ICEve{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.AddFile-module__button___VDTMn:hover{opacity:.8}", "",{"version":3,"sources":["webpack://./src/components/buttons/AddFile/AddFile.module.scss"],"names":[],"mappings":"AAAA,gCACE,8BAAA,CACA,YAAA,CACA,+BAAA,CAAA,cAAA,CACA,iCAAA,CACA,2BAAA,CACA,WAAA,CACA,UAAA,CACA,wDAAA,CACA,YAAA,CACA,uBAAA,CACA,WAAA,CACA,WAAA,CAEA,+DACE,wDAAA,CAEF,sCACE,UAAA","sourcesContent":[".button {\n  background-color: transparent;\n  outline: none;\n  border: unset;\n  background-position: center center;\n  background-repeat: no-repeat;\n  height: 25px;\n  width: 25px;\n  background-image: url(./images/attach.svg);\n  margin: 0 5px;\n  background-size: contain;\n  font-size: 0;\n  padding: 3px;\n\n  &.remove {\n    background-image: url(./images/close.svg);\n  }\n  &:hover {\n    opacity: 0.8;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "AddFile-module__button___VDTMn",
	"remove": "AddFile-module__remove___ICEve"
};
export default ___CSS_LOADER_EXPORT___;
