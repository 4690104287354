// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles__iframe___yADL2{border:none;background-color:#fff}.styles__iframe___yADL2 pre{color:#fff !important}", "",{"version":3,"sources":["webpack://./src/components/Iframe/styles.scss"],"names":[],"mappings":"AAAA,wBACE,WAAA,CACA,qBAAA,CAGF,4BACE,qBAAA","sourcesContent":[".iframe {\n  border: none;\n  background-color: #fff;\n}\n\n.iframe pre {\n  color: #fff !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iframe": "styles__iframe___yADL2"
};
export default ___CSS_LOADER_EXPORT___;
