// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles__slider___Uj578{width:250px;height:250px;position:absolute;left:50%;top:12%;-webkit-transform:translate(-50%, -12%);transform:translate(-50%, -12%);opacity:.6}", "",{"version":3,"sources":["webpack://./src/containers/temple/components/canvasOne/styles.scss"],"names":[],"mappings":"AAAA,wBACE,WAAA,CACA,YAAA,CAEA,iBAAA,CACA,QAAA,CACA,OAAA,CACA,uCAAA,CAAA,+BAAA,CACA,UAAA","sourcesContent":[".slider {\n  width: 250px;\n  height: 250px;\n  // background: rgba(0, 90, 50, 0.7);\n  position: absolute;\n  left: 50%;\n  top: 12%;\n  transform: translate(-50%, -12%);\n  opacity: 0.6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slider": "styles__slider___Uj578"
};
export default ___CSS_LOADER_EXPORT___;
