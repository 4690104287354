// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Slippage-module__container___F9o11{color:var(--grayscale-dark);font-size:.75rem}.Slippage-module__container___F9o11 .Slippage-module__value___J6UXS{color:var(--grayscale-primary)}", "",{"version":3,"sources":["webpack://./src/pages/teleport/swap/components/slippage/Slippage.module.scss"],"names":[],"mappings":"AAAA,oCACE,2BAAA,CACA,gBAAA,CAEA,oEACE,8BAAA","sourcesContent":[".container {\n  color: var(--grayscale-dark);\n  font-size: 0.75rem;\n\n  .value {\n    color: var(--grayscale-primary);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Slippage-module__container___F9o11",
	"value": "Slippage-module__value___J6UXS"
};
export default ___CSS_LOADER_EXPORT___;
