// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles__sideBar___RNK2O{position:fixed;top:130px;left:0;width:218px;background:#000;color:#fff;-webkit-transition:.3s;transition:.3s;z-index:4;opacity:.9;-webkit-box-shadow:1px 0 20px 2px #000;box-shadow:1px 0 20px 2px #000}.styles__sideBarHide___lA_7Z{left:-250px}.styles__portal___uMqyJ{overflow:hidden;position:absolute;top:0;left:0;right:0;bottom:0}", "",{"version":3,"sources":["webpack://./src/containers/application/styles.scss"],"names":[],"mappings":"AAAA,yBACE,cAAA,CACA,SAAA,CACA,MAAA,CACA,WAAA,CACA,eAAA,CACA,UAAA,CACA,sBAAA,CAAA,cAAA,CACA,SAAA,CACA,UAAA,CACA,sCAAA,CAAA,8BAAA,CAGF,6BACE,WAAA,CAGF,wBAEE,eAAA,CACA,iBAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA","sourcesContent":[".sideBar {\n  position: fixed;\n  top: 130px;\n  left: 0;\n  width: 218px;\n  background: #000000;\n  color: #fff;\n  transition: 0.3s;\n  z-index: 4;\n  opacity: 0.9;\n  box-shadow: 1px 0 20px 2px #000;\n}\n\n.sideBarHide {\n  left: -250px;\n}\n\n.portal {\n  // z-index: -1;\n  overflow: hidden;\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sideBar": "styles__sideBar___RNK2O",
	"sideBarHide": "styles__sideBarHide___lA_7Z",
	"portal": "styles__portal___uMqyJ"
};
export default ___CSS_LOADER_EXPORT___;
