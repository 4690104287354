// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Subnet-module__list___eOTHj{min-width:50%}.Subnet-module__list___eOTHj>li{display:-webkit-box;display:-ms-flexbox;display:flex;padding:10px 15px;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;width:100%}.Subnet-module__list___eOTHj>li>div{text-align:right}.Subnet-module__list___eOTHj>li:hover{background:-webkit-gradient(linear, left top, right top, color-stop(-3.3%, rgba(54, 214, 174, 0.15)), color-stop(32.75%, rgba(54, 214, 174, 0.072)), color-stop(62.88%, rgba(54, 214, 174, 0.0765)), to(rgba(54, 214, 174, 0.15)));background:linear-gradient(90deg, rgba(54, 214, 174, 0.15) -3.3%, rgba(54, 214, 174, 0.072) 32.75%, rgba(54, 214, 174, 0.0765) 62.88%, rgba(54, 214, 174, 0.15) 100%)}", "",{"version":3,"sources":["webpack://./src/features/cybernet/ui/pages/Subnet/Subnet.module.scss","webpack://./src/style/mixins.scss"],"names":[],"mappings":"AAEA,6BACE,aAAA,CAEA,gCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,iBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CACA,UAAA,CAEA,oCACE,gBAAA,CAGF,sCCdF,kOAAA,CAAA,qKAAA","sourcesContent":["@import '../../../../../style/mixins.scss';\n\n.list {\n  min-width: 50%;\n\n  > li {\n    display: flex;\n    padding: 10px 15px;\n    justify-content: space-between;\n    width: 100%;\n\n    > div {\n      text-align: right;\n    }\n\n    &:hover {\n      @include tableHover;\n    }\n  }\n}\n","@mixin tableHover {\n  background: linear-gradient(\n    90deg,\n    rgba(54, 214, 174, 0.15) -3.3%,\n    rgba(54, 214, 174, 0.072) 32.75%,\n    rgba(54, 214, 174, 0.0765) 62.88%,\n    rgba(54, 214, 174, 0.15) 100%\n  );\n}\n\n@mixin withShareIcon {\n  background-image: url('images/share.svg');\n  background-repeat: no-repeat;\n  background-position: right 1.5px;\n  background-size: 20px;\n  padding-right: 25px;\n}\n\n@mixin blueScroll {\n  &::-webkit-scrollbar-thumb {\n    background: var(--blue-light);\n  }\n  &::-webkit-scrollbar {\n    width: 2px;\n    height: 2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "Subnet-module__list___eOTHj"
};
export default ___CSS_LOADER_EXPORT___;
