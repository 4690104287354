// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BridgeItem-module__containerBridgeItem___We9iC{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:10px}.BridgeItem-module__containerBridgeItem___We9iC .BridgeItem-module__networks___N6og4{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.BridgeItem-module__containerBridgeItem___We9iC .BridgeItem-module__networksArrow___iU225{width:40px;height:30px;margin-left:-5px}", "",{"version":3,"sources":["webpack://./src/pages/teleport/mainScreen/components/BridgeAction/BridgeItem.module.scss"],"names":[],"mappings":"AAAA,gDACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,QAAA,CAEA,qFACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CAEA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAEA,0FACE,UAAA,CACA,WAAA,CACA,gBAAA","sourcesContent":[".containerBridgeItem {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 10px;\n\n  .networks {\n    display: flex;\n    // gap: 5px;\n    align-items: center;\n\n    &Arrow {\n      width: 40px;\n      height: 30px;\n      margin-left: -5px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerBridgeItem": "BridgeItem-module__containerBridgeItem___We9iC",
	"networks": "BridgeItem-module__networks___N6og4",
	"networksArrow": "BridgeItem-module__networksArrow___iU225"
};
export default ___CSS_LOADER_EXPORT___;
