// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../image/ionicons_svg_ios-copy.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Copy-module__copyBtn___roNFY{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center no-repeat;background-color:rgba(0,0,0,0);outline:none;border:none;height:15px;width:15px;cursor:pointer;margin-left:5px}.Copy-module__copyBtn___roNFY:hover{opacity:.8}", "",{"version":3,"sources":["webpack://./src/components/ui/Copy.module.scss"],"names":[],"mappings":"AACA,8BACE,0EAAA,CACA,8BAAA,CACA,YAAA,CACA,WAAA,CACA,WAAA,CACA,UAAA,CACA,cAAA,CACA,eAAA,CAEA,oCACE,UAAA","sourcesContent":["\n.copyBtn {\n  background: url(../../image/ionicons_svg_ios-copy.svg) center center no-repeat;\n  background-color: transparent;\n  outline: none;\n  border: none;\n  height: 15px;\n  width: 15px;\n  cursor: pointer;\n  margin-left: 5px;\n\n  &:hover {\n    opacity: 0.8;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copyBtn": "Copy-module__copyBtn___roNFY"
};
export default ___CSS_LOADER_EXPORT___;
