// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ItemBalance-module__wrapper___TimgX{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;gap:8px;font-size:16px}.ItemBalance-module__wrapper___TimgX .ItemBalance-module__text___VlTfw{color:var(--grayscale-dark)}", "",{"version":3,"sources":["webpack://./src/containers/mint/components/ItemBalance/ItemBalance.module.scss"],"names":[],"mappings":"AAAA,qCACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACE,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,OAAA,CACA,cAAA,CAEA,uEACE,2BAAA","sourcesContent":[".wrapper {\ndisplay: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 8px;\n  font-size: 16px;\n\n  .text {\n    color: var(--grayscale-dark);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ItemBalance-module__wrapper___TimgX",
	"text": "ItemBalance-module__text___VlTfw"
};
export default ___CSS_LOADER_EXPORT___;
