// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles__containerAboutGiftProgressBar___m__UQ{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;grid-gap:25px;margin:20px 0px}.styles__containerAboutGiftProgressBarTitle___bTCLa{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;font-size:18px}.styles__containerAboutGiftProgressBarFooter___mqYzy{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;width:100%;line-height:20px}", "",{"version":3,"sources":["webpack://./src/containers/portal/components/AboutGift/styles.scss"],"names":[],"mappings":"AACE,+CACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,aAAA,CACA,eAAA,CAEA,oDACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,cAAA,CAGF,qDACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CACA,UAAA,CACA,gBAAA","sourcesContent":[".containerAboutGift {\n  &ProgressBar {\n    display: flex;\n    flex-direction: column;\n    grid-gap: 25px;\n    margin: 20px 0px;\n  \n    &Title {\n      display: flex;\n      justify-content: center;\n      font-size: 18px;\n    }\n\n    &Footer {\n      display: flex;\n      justify-content: space-between;\n      width: 100%;\n      line-height: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerAboutGiftProgressBar": "styles__containerAboutGiftProgressBar___m__UQ",
	"containerAboutGiftProgressBarTitle": "styles__containerAboutGiftProgressBarTitle___bTCLa",
	"containerAboutGiftProgressBarFooter": "styles__containerAboutGiftProgressBarFooter___mqYzy"
};
export default ___CSS_LOADER_EXPORT___;
