// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CarouselOld-module__carousel___w8nIm{width:100%;max-width:600px;overflow-x:hidden;height:40px}.CarouselOld-module__carousel___w8nIm .CarouselOld-module__slidesContainer___PqBPm{height:100%;position:relative}.CarouselOld-module__carousel___w8nIm .CarouselOld-module__slides___wCICK{height:100%;position:absolute;top:0;left:0;display:-webkit-box;display:-ms-flexbox;display:flex}.CarouselOld-module__transition___Ca7O_{-webkit-transition:ease all .5s;transition:ease all .5s}", "",{"version":3,"sources":["webpack://./src/components/Tabs/Carousel/CarouselOld/CarouselOld.module.scss"],"names":[],"mappings":"AAAA,sCACE,UAAA,CACA,eAAA,CACA,iBAAA,CACA,WAAA,CAEA,mFACE,WAAA,CAEA,iBAAA,CAGF,0EACE,WAAA,CACA,iBAAA,CACA,KAAA,CACA,MAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CAIJ,wCACE,+BAAA,CAAA,uBAAA","sourcesContent":[".carousel {\n  width: 100%;\n  max-width: 600px;\n  overflow-x: hidden;\n  height: 40px;\n\n  .slidesContainer {\n    height: 100%;\n    // overflow: hidden;\n    position: relative;\n  }\n\n  .slides {\n    height: 100%;\n    position: absolute;\n    top: 0;\n    left: 0;\n    display: flex;\n  }\n}\n\n.transition {\n  transition: ease all 0.5s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"carousel": "CarouselOld-module__carousel___w8nIm",
	"slidesContainer": "CarouselOld-module__slidesContainer___PqBPm",
	"slides": "CarouselOld-module__slides___wCICK",
	"transition": "CarouselOld-module__transition___Ca7O_"
};
export default ___CSS_LOADER_EXPORT___;
