// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Meta-module__meta___UcRQj{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:5px 20px;z-index:1;font-size:14px}.Meta-module__meta___UcRQj>:first-child{position:relative;top:4px}.Meta-module__size___XLbID{white-space:nowrap}", "",{"version":3,"sources":["webpack://./src/components/search/Spark/Meta/Meta.module.scss"],"names":[],"mappings":"AAAA,2BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,YAAA,CAGA,SAAA,CAEA,cAAA,CAEA,wCACE,iBAAA,CACA,OAAA,CAIJ,2BACE,kBAAA","sourcesContent":[".meta {\n  display: flex;\n  align-items: center;\n  gap: 5px 20px;\n\n  // temp\n  z-index: 1;\n\n  font-size: 14px;\n\n  & > :first-child {\n    position: relative;\n    top: 4px;\n  }\n}\n\n.size {\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"meta": "Meta-module__meta___UcRQj",
	"size": "Meta-module__size___XLbID"
};
export default ___CSS_LOADER_EXPORT___;
