// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ParticleAvatar-module__wrapper___q48rZ{height:50px;width:50px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;border-radius:10px;font-size:20px;background:rgba(217,217,217,.1)}.ParticleAvatar-module__wrapper___q48rZ img{max-height:100%;max-width:100%}", "",{"version":3,"sources":["webpack://./src/features/sense/ui/components/ParticleAvatar/ParticleAvatar.module.scss"],"names":[],"mappings":"AAAA,wCACE,WAAA,CACA,UAAA,CAEA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CAEA,kBAAA,CACA,cAAA,CAOA,+BAAA,CALA,4CACE,eAAA,CACA,cAAA","sourcesContent":[".wrapper {\n  height: 50px;\n  width: 50px;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  border-radius: 10px;\n  font-size: 20px;\n\n  img {\n    max-height: 100%;\n    max-width: 100%;\n  }\n\n  background: rgba(217, 217, 217, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ParticleAvatar-module__wrapper___q48rZ"
};
export default ___CSS_LOADER_EXPORT___;
