const slideData = {
  collaborativeNeural: {
    title: 'collaborative neural network',
    amount: 0,
    denom: '',
    keyAmount: 'cyberlinks',
    change: { time: 0, amount: 0 },
  },
  superintelligentMoon: {
    title: 'superintelligent moon network state',
    amount: 0,
    denom: '',
    keyAmount: 'citizens',
    change: { time: 0, amount: 0 },
  },
  provable: {
    title: 'provable, efficient and fair game',
    amount: 0,
    denom: '%',
    keyAmount: 'BOOT gifted',
    change: { time: 0, amount: 0 },
  },
  fastGrowing: {
    title: 'fast growing vibrant economy',
    amount: 0,
    denom: '',
    keyAmount: 'cap in H',
    change: { time: 0, amount: 0 },
  },
  everGrowing: {
    title: 'ever-growing planet-scale singleton',
    amount: 0,
    denom: '',
    keyAmount: 'neurons',
    change: { time: 0, amount: 0 },
  },
  biggestUseful: {
    title: 'biggest useful gpu computer',
    amount: 0,
    denom: '',
    keyAmount: 'heroes',
    change: { time: 0, amount: 0 },
  },
  unstoppablePublic: {
    title: 'unstoppable public knowledge graph',
    amount: 0,
    denom: '',
    keyAmount: 'gpu memory',
    change: { time: 0, amount: 0 },
  },
  hubFor: {
    title: 'hub for gpu computations',
    denom: '',
    amount: 2,
    keyAmount: 'networks',
    change: { time: 0, amount: 0 },
  },
  permissionlessOnchain: {
    title: 'permissionless onchain learning',
    amount: 0,
    denom: '',
    keyAmount: 'beta',
    change: { time: 0, amount: 0 },
  },
  autonomousSemantic: {
    title: 'autonomous semantic programs',
    amount: 0,
    denom: '',
    keyAmount: 'progs',
    change: { time: 0, amount: 0 },
  },
  nextGen: {
    title: 'next gen unstoppable content resolver',
    amount: 0,
    denom: '',
    keyAmount: 'particles',
    change: { time: 0, amount: 0 },
  },
  realDistributed: {
    title: 'real distributed mind',
    amount: 0,
    denom: '',
    keyAmount: 'bits of negentropy',
    change: { time: 0, amount: 0 },
  },
  futureOf: {
    title: 'future of ai is cyber',
    amount: 0,
    denom: '',
    keyAmount: 'BOOT for ATOM',
    change: { time: 0, amount: 0 },
  },
};

export default slideData;
