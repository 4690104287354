// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContractsTable-module__wrapper___xMZzj table thead{display:none}.ContractsTable-module__nameCell____ADML{position:relative;padding-left:30px;text-align:left}.ContractsTable-module__nameCell____ADML>span{color:var(--green-light);position:absolute;left:10px}.ContractsTable-module__nameCell____ADML>a{display:-webkit-inline-box;display:-ms-inline-flexbox;display:inline-flex}.ContractsTable-module__nameCell____ADML>a>img{margin-right:7px;height:20px;width:20px}.ContractsTable-module__descriptionCell___Il2EE{text-align:left;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:0 7px}.ContractsTable-module__smallText___PLIYH{font-size:14px;color:#a0a0a0}", "",{"version":3,"sources":["webpack://./src/features/cybernet/ui/pages/Main/ContractsTable/ContractsTable.module.scss"],"names":[],"mappings":"AACE,oDACE,YAAA,CAIJ,yCACE,iBAAA,CACA,iBAAA,CACA,eAAA,CAEA,8CACE,wBAAA,CACA,iBAAA,CACA,SAAA,CAGF,2CACE,0BAAA,CAAA,0BAAA,CAAA,mBAAA,CAEA,+CACE,gBAAA,CACA,WAAA,CACA,UAAA,CAKN,gDACE,eAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,SAAA,CAGF,0CACE,cAAA,CACA,aAAA","sourcesContent":[".wrapper {\n  table thead {\n    display: none;\n  }\n}\n\n.nameCell {\n  position: relative;\n  padding-left: 30px;\n  text-align: left;\n\n  > span {\n    color: var(--green-light);\n    position: absolute;\n    left: 10px;\n  }\n\n  > a {\n    display: inline-flex;\n\n    > img {\n      margin-right: 7px;\n      height: 20px;\n      width: 20px;\n    }\n  }\n}\n\n.descriptionCell {\n  text-align: left;\n  display: flex;\n  align-items: center;\n  gap: 0 7px;\n}\n\n.smallText {\n  font-size: 14px;\n  color: #a0a0a0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ContractsTable-module__wrapper___xMZzj",
	"nameCell": "ContractsTable-module__nameCell____ADML",
	"descriptionCell": "ContractsTable-module__descriptionCell___Il2EE",
	"smallText": "ContractsTable-module__smallText___PLIYH"
};
export default ___CSS_LOADER_EXPORT___;
