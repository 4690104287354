// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KeywordButton-module__keywordBtn___abL3L{font-size:16px;padding:3px 15px;text-align:center;border-radius:15px;border:1px solid rgba(31,203,255,.2)}.KeywordButton-module__keywordBtn___abL3L,.KeywordButton-module__keywordBtn___abL3L:hover{color:var(--blue-light)}.KeywordButton-module__keywordBtn___abL3L:hover{opacity:.7}", "",{"version":3,"sources":["webpack://./src/pages/oracle/landing/components/KeywordButton/KeywordButton.module.scss"],"names":[],"mappings":"AAAA,0CAOE,cAAA,CACA,gBAAA,CACA,iBAAA,CAEA,kBAAA,CACA,oCAAA,CAXA,0FAGE,uBAAA,CAUF,gDACE,UAAA","sourcesContent":[".keywordBtn {\n  &,\n  // global styles\n  &:hover {\n    color: var(--blue-light);\n  }\n\n  font-size: 16px;\n  padding: 3px 15px;\n  text-align: center;\n\n  border-radius: 15px;\n  border: 1px solid rgba(31, 203, 255, 0.2);\n\n  &:hover {\n    opacity: 0.7;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"keywordBtn": "KeywordButton-module__keywordBtn___abL3L"
};
export default ___CSS_LOADER_EXPORT___;
