import { useLocation, useNavigate, useParams } from 'react-router-dom';

// TODO: Tmp solution for legacy components, - remove when all components are migrated to hooks
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

export default withRouter;
