// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__search___g2L5Y{width:62%;margin:10px auto}.styles-module__search___g2L5Y .styles-module__infiniteScroll___l02CN{margin-top:12px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.styles-module__search___g2L5Y .styles-module__infiniteScroll___l02CN>*{width:100%}@media(max-width: 768px){.styles-module__search___g2L5Y{width:90%}}.styles-module__actionBar___c2PjE{position:fixed;left:0;z-index:1}.styles-module__filters___GUaIX,.styles-module__meta___UFtdn{margin-top:20px}.styles-module__loader___gtf5p{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:100%}", "",{"version":3,"sources":["webpack://./src/containers/governance/styles.module.scss"],"names":[],"mappings":"AAAA,+BACE,SAAA,CACA,gBAAA,CAEA,sEACE,eAAA,CAEA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAEA,wEACE,UAAA,CAIJ,yBAhBF,+BAiBI,SAAA,CAAA,CAIJ,kCACE,cAAA,CACA,MAAA,CACA,SAAA,CAGF,6DAEE,eAAA,CAGF,+BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,WAAA","sourcesContent":[".search {\n  width: 62%;\n  margin: 10px auto;\n\n  .infiniteScroll {\n    margin-top: 12px;\n\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    > * {\n      width: 100%;\n    }\n  }\n\n  @media (max-width: 768px) {\n    width: 90%;\n  }\n}\n\n.actionBar {\n  position: fixed;\n  left: 0;\n  z-index: 1;\n}\n\n.filters,\n.meta {\n  margin-top: 20px;\n}\n\n.loader {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": "styles-module__search___g2L5Y",
	"infiniteScroll": "styles-module__infiniteScroll___l02CN",
	"actionBar": "styles-module__actionBar___c2PjE",
	"filters": "styles-module__filters___GUaIX",
	"meta": "styles-module__meta___UFtdn",
	"loader": "styles-module__loader___gtf5p"
};
export default ___CSS_LOADER_EXPORT___;
