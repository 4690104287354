// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextDenom-module__denom___PFMmE{text-transform:none;text-transform:initial;word-break:break-word}", "",{"version":3,"sources":["webpack://./src/components/valueImg/TextDenom.module.scss"],"names":[],"mappings":"AAAA,iCACE,mBAAA,CAAA,sBAAA,CACA,qBAAA","sourcesContent":[".denom {\n  text-transform: initial;\n  word-break: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"denom": "TextDenom-module__denom___PFMmE"
};
export default ___CSS_LOADER_EXPORT___;
