// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".account-module__container___ZTXmv{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-ms-flex-wrap:nowrap;flex-wrap:nowrap;gap:10px}.account-module__avatar___VDd0O{width:30px;height:30px;border-radius:50%}.account-module__moniker___y_grx{color:var(--primary-color);padding:0;white-space:nowrap}", "",{"version":3,"sources":["webpack://./src/components/account/account.module.scss"],"names":[],"mappings":"AAAA,mCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,oBAAA,CAAA,gBAAA,CACA,QAAA,CAGF,gCACE,UAAA,CACA,WAAA,CACA,iBAAA,CAGF,iCACE,0BAAA,CACA,SAAA,CACA,kBAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  flex-wrap: nowrap;\n  gap: 10px;\n}\n\n.avatar {\n  width: 30px;\n  height: 30px;\n  border-radius: 50%;\n}\n\n.moniker {\n  color: var(--primary-color);\n  padding: 0;\n  white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "account-module__container___ZTXmv",
	"avatar": "account-module__avatar___VDd0O",
	"moniker": "account-module__moniker___y_grx"
};
export default ___CSS_LOADER_EXPORT___;
