// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Date-module__date___Wgv6X{font-size:12px;white-space:nowrap;color:rgba(255,255,255,.5)}", "",{"version":3,"sources":["webpack://./src/features/sense/ui/components/Date/Date.module.scss"],"names":[],"mappings":"AAAA,2BACE,cAAA,CACA,kBAAA,CACA,0BAAA","sourcesContent":[".date {\n  font-size: 12px;\n  white-space: nowrap;\n  color: rgba(255, 255, 255, 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date": "Date-module__date___Wgv6X"
};
export default ___CSS_LOADER_EXPORT___;
