// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContainerMsgsType-module__container___t0EnF{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;gap:15px;margin-bottom:20px}.ContainerMsgsType-module__container___t0EnF>:nth-child(2){padding-left:20px}.ContainerMsgsType-module__container___t0EnF .ContainerMsgsType-module__type___IN5ZA{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:10px;font-size:18px}", "",{"version":3,"sources":["webpack://./src/containers/txs/components/ContainerMsgsType.module.scss"],"names":[],"mappings":"AAAA,6CACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,QAAA,CACA,kBAAA,CAEA,2DACE,iBAAA,CAGF,qFACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,QAAA,CACA,cAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n  margin-bottom: 20px;\n\n  >:nth-child(2) {\n    padding-left: 20px;\n  }\n\n  .type {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    font-size: 18px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ContainerMsgsType-module__container___t0EnF",
	"type": "ContainerMsgsType-module__type___IN5ZA"
};
export default ___CSS_LOADER_EXPORT___;
