// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tooltip-module__tooltipContainer___JxEbe{background-color:#000;border:none;color:#fff;white-space:normal;-webkit-box-shadow:0px 0px 4px 1px #38d6ae;box-shadow:0px 0px 4px 1px #38d6ae;z-index:4;padding:10px 12px;max-width:300px;font-size:13px}.Tooltip-module__tooltipContainerBorderNone___C9hz2{-webkit-box-shadow:none;box-shadow:none}", "",{"version":3,"sources":["webpack://./src/components/tooltip/Tooltip.module.scss"],"names":[],"mappings":"AAAA,0CACE,qBAAA,CACA,WAAA,CACA,UAAA,CACA,kBAAA,CACA,0CAAA,CAAA,kCAAA,CACA,SAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CAEA,oDACE,uBAAA,CAAA,eAAA","sourcesContent":[".tooltipContainer {\n  background-color: #000000;\n  border: none;\n  color: #fff;\n  white-space: normal;\n  box-shadow: 0px 0px 4px 1px #38d6ae;\n  z-index: 4;\n  padding: 10px 12px;\n  max-width: 300px;\n  font-size: 13px;\n\n  &BorderNone {\n    box-shadow: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipContainer": "Tooltip-module__tooltipContainer___JxEbe",
	"tooltipContainerBorderNone": "Tooltip-module__tooltipContainerBorderNone___C9hz2"
};
export default ___CSS_LOADER_EXPORT___;
