// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CyberlinksGraph-module__wrapper___FS20u{position:relative}.CyberlinksGraph-module__loaderWrapper___kAWon{position:absolute;top:45%;width:100%;text-align:center;z-index:1;font-size:22px}", "",{"version":3,"sources":["webpack://./src/features/cyberlinks/CyberlinksGraph/CyberlinksGraph.module.scss"],"names":[],"mappings":"AAAA,yCACE,iBAAA,CAGF,+CACE,iBAAA,CACA,OAAA,CACA,UAAA,CACA,iBAAA,CACA,SAAA,CACA,cAAA","sourcesContent":[".wrapper {\n  position: relative;\n}\n\n.loaderWrapper {\n  position: absolute;\n  top: 45%;\n  width: 100%;\n  text-align: center;\n  z-index: 1;\n  font-size: 22px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "CyberlinksGraph-module__wrapper___FS20u",
	"loaderWrapper": "CyberlinksGraph-module__loaderWrapper___kAWon"
};
export default ___CSS_LOADER_EXPORT___;
