// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".stylesHistoryInfo__containerHistoryInfoTitle___gidAO{display:inline-block;margin-bottom:20px;font-size:18px}.stylesHistoryInfo__containerHistoryInfoItemTitle____XO1x{margin-bottom:10px;font-size:16px}", "",{"version":3,"sources":["webpack://./src/containers/wasm/contract/stylesHistoryInfo.scss"],"names":[],"mappings":"AACE,sDACE,oBAAA,CACA,kBAAA,CACA,cAAA,CAGA,0DACE,kBAAA,CACA,cAAA","sourcesContent":[".containerHistoryInfo {\n  &Title {\n    display: inline-block;\n    margin-bottom: 20px;\n    font-size: 18px;\n  }\n  &Item {\n    &Title {\n      margin-bottom: 10px;\n      font-size: 16px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerHistoryInfoTitle": "stylesHistoryInfo__containerHistoryInfoTitle___gidAO",
	"containerHistoryInfoItemTitle": "stylesHistoryInfo__containerHistoryInfoItemTitle____XO1x"
};
export default ___CSS_LOADER_EXPORT___;
