// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdviserHoverWrapper-module__wrapper___DuNsO{cursor:help;display:inline-block}", "",{"version":3,"sources":["webpack://./src/features/adviser/AdviserHoverWrapper/AdviserHoverWrapper.module.scss"],"names":[],"mappings":"AAAA,6CACE,WAAA,CACA,oBAAA","sourcesContent":[".wrapper {\n  cursor: help;\n  display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "AdviserHoverWrapper-module__wrapper___DuNsO"
};
export default ___CSS_LOADER_EXPORT___;
