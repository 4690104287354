// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SubnetHeader-module__wrapper___lWs3n{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-pack:distribute;justify-content:space-around;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:0 13px}.SubnetHeader-module__wrapper___lWs3n .SubnetHeader-module__name___pGbNS{color:var(--pink);font-size:30px;margin-top:-5px;display:block}.SubnetHeader-module__wrapper___lWs3n .SubnetHeader-module__logo___Jg22q img{max-width:80px}.SubnetHeader-module__item___H9J1p{height:100%;-ms-flex-item-align:stretch;align-self:stretch;font-size:20px}.SubnetHeader-module__item___H9J1p h6{color:#616161;font-size:14px;font-weight:400;display:block;margin-bottom:15px}", "",{"version":3,"sources":["webpack://./src/features/cybernet/ui/pages/Subnet/SubnetHeader/SubnetHeader.module.scss"],"names":[],"mappings":"AAAA,sCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,4BAAA,CAEA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,UAAA,CAEA,yEACE,iBAAA,CACA,cAAA,CACA,eAAA,CACA,aAAA,CAIA,6EACE,cAAA,CAKN,mCACE,WAAA,CACA,2BAAA,CAAA,kBAAA,CAEA,cAAA,CAEA,sCACE,aAAA,CACA,cAAA,CACA,eAAA,CACA,aAAA,CAEA,kBAAA","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: space-around;\n\n  align-items: center;\n  gap: 0 13px;\n\n  .name {\n    color: var(--pink);\n    font-size: 30px;\n    margin-top: -5px;\n    display: block;\n  }\n\n  .logo {\n    img {\n      max-width: 80px;\n    }\n  }\n}\n\n.item {\n  height: 100%;\n  align-self: stretch;\n\n  font-size: 20px;\n\n  h6 {\n    color: #616161;\n    font-size: 14px;\n    font-weight: 400;\n    display: block;\n\n    margin-bottom: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "SubnetHeader-module__wrapper___lWs3n",
	"name": "SubnetHeader-module__name___pGbNS",
	"logo": "SubnetHeader-module__logo___Jg22q",
	"item": "SubnetHeader-module__item___H9J1p"
};
export default ___CSS_LOADER_EXPORT___;
