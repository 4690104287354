// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CoinAmount-module__coinAmount___Bwwtd{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:0 4px;color:var(--green-light)}.CoinAmount-module__coinAmount___Bwwtd img{width:13px !important;height:13px !important}.CoinAmount-module__coinAmount___Bwwtd.CoinAmount-module__send___bsvCq span{color:#ff5c00}", "",{"version":3,"sources":["webpack://./src/features/sense/ui/components/CoinAmount/CoinAmount.module.scss"],"names":[],"mappings":"AAAA,uCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,SAAA,CACA,wBAAA,CAEA,2CACE,qBAAA,CACA,sBAAA,CAIA,4EACE,aAAA","sourcesContent":[".coinAmount {\n  display: flex;\n  align-items: center;\n  gap: 0 4px;\n  color: var(--green-light);\n\n  img {\n    width: 13px !important;\n    height: 13px !important;\n  }\n\n  &.send {\n    span {\n      color: #ff5c00;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"coinAmount": "CoinAmount-module__coinAmount___Bwwtd",
	"send": "CoinAmount-module__send___bsvCq"
};
export default ___CSS_LOADER_EXPORT___;
