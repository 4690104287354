// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Creator-module__date___XWnLL{white-space:nowrap;color:rgba(255,255,255,.5)}", "",{"version":3,"sources":["webpack://./src/components/search/Spark/LeftMeta/Creator/Creator.module.scss"],"names":[],"mappings":"AAAA,8BACE,kBAAA,CACA,0BAAA","sourcesContent":[".date {\n  white-space: nowrap;\n  color: rgba(255, 255, 255, 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date": "Creator-module__date___XWnLL"
};
export default ___CSS_LOADER_EXPORT___;
