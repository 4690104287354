// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InputNumberDecimalScale-module__containerAvailableAmount___Rzwmx{height:42px}.InputNumberDecimalScale-module__containerValue___VdqFb{padding:0 13px;font-size:1.25rem;color:var(--primary-color);height:42px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}", "",{"version":3,"sources":["webpack://./src/pages/teleport/components/Inputs/InputNumberDecimalScale/InputNumberDecimalScale.module.scss"],"names":[],"mappings":"AAAA,kEACE,WAAA,CAGF,wDACE,cAAA,CACA,iBAAA,CACA,0BAAA,CACA,WAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,oBAAA,CAAA,iBAAA,CAAA,wBAAA","sourcesContent":[".containerAvailableAmount {\n  height: 42px;\n}\n\n.containerValue {\n  padding: 0 13px;\n  font-size: 1.25rem;\n  color: var(--primary-color);\n  height: 42px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerAvailableAmount": "InputNumberDecimalScale-module__containerAvailableAmount___Rzwmx",
	"containerValue": "InputNumberDecimalScale-module__containerValue___VdqFb"
};
export default ___CSS_LOADER_EXPORT___;
