// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GitHub-module__wrapper___hl4bZ{display:-webkit-box;display:-ms-flexbox;display:flex}", "",{"version":3,"sources":["webpack://./src/pages/Social/GitHub/GitHub.module.scss"],"names":[],"mappings":"AAAA,gCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA","sourcesContent":[".wrapper {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "GitHub-module__wrapper___hl4bZ"
};
export default ___CSS_LOADER_EXPORT___;
