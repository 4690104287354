// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Main-module__wrapper___I5yGQ footer a{position:fixed;z-index:2;right:5vw;bottom:35px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.Main-module__senseBtn___zHcCv{position:fixed;top:45%;z-index:3}", "",{"version":3,"sources":["webpack://./src/layouts/Main.module.scss"],"names":[],"mappings":"AAEI,uCACE,cAAA,CACA,SAAA,CACA,SAAA,CACA,WAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAKN,+BACE,cAAA,CACA,OAAA,CACA,SAAA","sourcesContent":[".wrapper {\n  footer {\n    a {\n      position: fixed;\n      z-index: 2;\n      right: 5vw;\n      bottom: 35px;\n      display: flex;\n      align-items: center;\n    }\n  }\n}\n\n.senseBtn {\n  position: fixed;\n  top: 45%;\n  z-index: 3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Main-module__wrapper___I5yGQ",
	"senseBtn": "Main-module__senseBtn___zHcCv"
};
export default ___CSS_LOADER_EXPORT___;
