// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dots-module__loaderDot___Y8x8r{display:inline-block}.Dots-module__loaderDot___Y8x8r span{color:#3ab793;font-size:30px;-webkit-animation:Dots-module__blinkDot___MB3rN 1.4s infinite;animation:Dots-module__blinkDot___MB3rN 1.4s infinite;border-radius:50%;-webkit-animation-fill-mode:both;animation-fill-mode:both;line-height:0}.Dots-module__loaderDot___Y8x8r span:nth-child(2){-webkit-animation-delay:.2s;animation-delay:.2s}.Dots-module__loaderDot___Y8x8r span:nth-child(3){-webkit-animation-delay:.4s;animation-delay:.4s}@-webkit-keyframes Dots-module__blinkDot___MB3rN{0%{opacity:.2}20%{opacity:1}100%{opacity:.2}}@keyframes Dots-module__blinkDot___MB3rN{0%{opacity:.2}20%{opacity:1}100%{opacity:.2}}.Dots-module__loaderDotBig___QuBTG span{font-size:60px !important}", "",{"version":3,"sources":["webpack://./src/components/ui/Dots.module.scss"],"names":[],"mappings":"AAAA,gCACE,oBAAA,CAEA,qCACE,aAAA,CACA,cAAA,CACA,6DAAA,CAAA,qDAAA,CACA,iBAAA,CACA,gCAAA,CAAA,wBAAA,CACA,aAAA,CAEA,kDACE,2BAAA,CAAA,mBAAA,CAGF,kDACE,2BAAA,CAAA,mBAAA,CAKN,iDACE,GACE,UAAA,CAGF,IACE,SAAA,CAGF,KACE,UAAA,CAAA,CAVJ,yCACE,GACE,UAAA,CAGF,IACE,SAAA,CAGF,KACE,UAAA,CAAA,CAKF,wCACE,yBAAA","sourcesContent":[".loaderDot {\n  display: inline-block;\n\n  span {\n    color: #3ab793;\n    font-size: 30px;\n    animation: blinkDot 1.4s infinite;\n    border-radius: 50%;\n    animation-fill-mode: both;\n    line-height: 0;\n\n    &:nth-child(2) {\n      animation-delay: 0.2s;\n    }\n\n    &:nth-child(3) {\n      animation-delay: 0.4s;\n    }\n  }\n}\n\n@keyframes blinkDot {\n  0% {\n    opacity: 0.2;\n  }\n\n  20% {\n    opacity: 1;\n  }\n\n  100% {\n    opacity: 0.2;\n  }\n}\n\n.loaderDotBig {\n  span {\n    font-size: 60px !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderDot": "Dots-module__loaderDot___Y8x8r",
	"blinkDot": "Dots-module__blinkDot___MB3rN",
	"loaderDotBig": "Dots-module__loaderDotBig___QuBTG"
};
export default ___CSS_LOADER_EXPORT___;
