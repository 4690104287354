// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__containerDetailsBalance___MJWNg{display:grid;grid-gap:30px;gap:30px}.styles-module__containerRowItem___BROpi{display:grid;grid-template-columns:397px 1fr}.styles-module__containerRowItemValueBalance___YlFf5{display:grid;grid-template-columns:90px 1fr;grid-gap:10px;gap:10px}.styles-module__containerRowItemValueBalanceText___juah8{padding-left:12px;white-space:nowrap}.styles-module__containerRowItemValueBalanceValue___G0pdE{justify-self:flex-end}.styles-module__containerRowItemMarket___gTO8t{justify-self:flex-end}", "",{"version":3,"sources":["webpack://./src/containers/sigma/components/cardUi/DetailsBalance/styles.module.scss"],"names":[],"mappings":"AAAA,gDACE,YAAA,CACA,aAAA,CAAA,QAAA,CAGF,yCACE,YAAA,CACA,+BAAA,CAEA,qDACE,YAAA,CACA,8BAAA,CACA,aAAA,CAAA,QAAA,CAEA,yDACE,iBAAA,CACA,kBAAA,CAGF,0DACE,qBAAA,CAIJ,+CACE,qBAAA","sourcesContent":[".containerDetailsBalance {\n  display: grid;\n  gap: 30px;\n}\n\n.containerRowItem {\n  display: grid;\n  grid-template-columns: 397px 1fr;\n\n  &ValueBalance {\n    display: grid;\n    grid-template-columns: 90px 1fr;\n    gap: 10px;\n\n    &Text {\n      padding-left: 12px;\n      white-space: nowrap;\n    }\n\n    &Value {\n      justify-self: flex-end;\n    }\n  }\n\n  &Market {\n    justify-self: flex-end;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerDetailsBalance": "styles-module__containerDetailsBalance___MJWNg",
	"containerRowItem": "styles-module__containerRowItem___BROpi",
	"containerRowItemValueBalance": "styles-module__containerRowItemValueBalance___YlFf5",
	"containerRowItemValueBalanceText": "styles-module__containerRowItemValueBalanceText___juah8",
	"containerRowItemValueBalanceValue": "styles-module__containerRowItemValueBalanceValue___G0pdE",
	"containerRowItemMarket": "styles-module__containerRowItemMarket___gTO8t"
};
export default ___CSS_LOADER_EXPORT___;
