// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Layout-module__wrapper___WzBpw{display:grid;grid-template-columns:200px minmax(200px, 62%);grid-template-rows:1fr;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;height:70vh;max-height:calc(100vh - 150px - 102px)}.Layout-module__wrapper___WzBpw>div>div{height:100%}.Layout-module__wrapper___WzBpw>:first-child>div{overflow-x:hidden}.Layout-module__wrapper___WzBpw>:nth-child(2){left:-2px;position:relative;overflow-x:auto}", "",{"version":3,"sources":["webpack://./src/pages/Settings/Layout/Layout.module.scss"],"names":[],"mappings":"AAEA,gCACE,YAAA,CACA,8CAAA,CACA,sBAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CAEA,WAAA,CACA,sCAAA,CAGA,wCACE,WAAA,CAIA,iDAEE,iBAAA,CAIJ,8CACE,SAAA,CACA,iBAAA,CACA,eAAA","sourcesContent":["@import '../../../layouts/variables.module.scss';\n\n.wrapper {\n  display: grid;\n  grid-template-columns: 200px minmax(200px, 62%);\n  grid-template-rows: 1fr;\n  justify-content: center;\n\n  height: 70vh;\n  max-height: calc(100vh - $reservedTopHeight - $actionBarHeight);\n\n  // Display\n  >div>div {\n    height: 100%;\n  }\n\n  > :first-child {\n    >div {\n      // don't understand this scroll\n      overflow-x: hidden;\n    }\n  }\n\n  > :nth-child(2) {\n    left: -2px;\n    position: relative;\n    overflow-x: auto;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Layout-module__wrapper___WzBpw"
};
export default ___CSS_LOADER_EXPORT___;
