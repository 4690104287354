// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableDataTxs-module__imgStatus___Kcsyb{width:20px;height:20px;margin-right:5px}.TableDataTxs-module__type___p1KMm{max-width:100px}", "",{"version":3,"sources":["webpack://./src/components/TableTxsInfinite/component/TableDataTxs.module.scss"],"names":[],"mappings":"AAAA,wCACE,UAAA,CACA,WAAA,CACA,gBAAA,CAGF,mCACE,eAAA","sourcesContent":[".imgStatus {\n  width: 20px;\n  height: 20px;\n  margin-right: 5px;\n}\n\n.type {\n  max-width: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imgStatus": "TableDataTxs-module__imgStatus___Kcsyb",
	"type": "TableDataTxs-module__type___p1KMm"
};
export default ___CSS_LOADER_EXPORT___;
