// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TeleportMainScreen-module__container___Uy3jv{width:62%;margin:0 auto;display:grid;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;grid-template-columns:repeat(2, 375px);grid-gap:50px;gap:50px}@media(max-width: 580px){.TeleportMainScreen-module__container___Uy3jv{grid-template-columns:1fr;width:95%}.TeleportMainScreen-module__container___Uy3jv>:nth-child(1){display:none}}.TeleportMainScreen-module__cardContainer___RzoGg{display:grid;grid-gap:15px;gap:15px}", "",{"version":3,"sources":["webpack://./src/pages/teleport/mainScreen/TeleportMainScreen.module.scss"],"names":[],"mappings":"AAAA,8CACE,SAAA,CACA,aAAA,CACA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,sCAAA,CACA,aAAA,CAAA,QAAA,CAEA,yBARF,8CASI,yBAAA,CACA,SAAA,CAEA,4DACE,YAAA,CAAA,CAKN,kDACE,YAAA,CACA,aAAA,CAAA,QAAA","sourcesContent":[".container {\n  width: 62%;\n  margin: 0 auto;\n  display: grid;\n  justify-content: center;\n  grid-template-columns: repeat(2, 375px);\n  gap: 50px;\n\n  @media (max-width: 580px) {\n    grid-template-columns: 1fr;\n    width: 95%;\n\n    >:nth-child(1) {\n      display: none;\n    }\n  }\n}\n\n.cardContainer {\n  display: grid;\n  gap: 15px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TeleportMainScreen-module__container___Uy3jv",
	"cardContainer": "TeleportMainScreen-module__cardContainer___RzoGg"
};
export default ___CSS_LOADER_EXPORT___;
