// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles__containerChartTotal___S7am_{display:-webkit-box;display:-ms-flexbox;display:flex;width:100%;height:3px;position:relative;border-radius:2px;overflow:hidden}.styles__containerChartTotal___S7am_>div{height:inherit}.styles__containerChartTotalLiquid___ZM7Al{background-color:var(--green-light)}.styles__containerChartTotalFrozen___EAotL{background-color:#00e5ff}.styles__containerChartTotalMelting___PqaNX{background-color:#2979ff}.styles__containerChartTotalGrowth___zphGw{background-color:var(--yellow)}.styles__containerChartTotalCommission___LgqrS{background-color:purple}", "",{"version":3,"sources":["webpack://./src/containers/sigma/components/cardUi/ChartTotal/styles.scss"],"names":[],"mappings":"AAAA,qCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,UAAA,CACA,UAAA,CACA,iBAAA,CACA,iBAAA,CACA,eAAA,CAEA,yCACE,cAAA,CAGF,2CACE,mCAAA,CAGF,2CACE,wBAAA,CAGF,4CACE,wBAAA,CAGF,2CACE,8BAAA,CAGF,+CACE,uBAAA","sourcesContent":[".containerChartTotal {\n  display: flex;\n  width: 100%;\n  height: 3px;\n  position: relative;\n  border-radius: 2px;\n  overflow: hidden;\n\n  > div {\n    height: inherit;\n  }\n\n  &Liquid {\n    background-color: var(--green-light);\n  }\n\n  &Frozen {\n    background-color: #00e5ff;\n  }\n\n  &Melting {\n    background-color: #2979ff;\n  }\n\n  &Growth {\n    background-color: var(--yellow);\n  }\n\n  &Commission {\n    background-color: purple;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerChartTotal": "styles__containerChartTotal___S7am_",
	"containerChartTotalLiquid": "styles__containerChartTotalLiquid___ZM7Al",
	"containerChartTotalFrozen": "styles__containerChartTotalFrozen___EAotL",
	"containerChartTotalMelting": "styles__containerChartTotalMelting___PqaNX",
	"containerChartTotalGrowth": "styles__containerChartTotalGrowth___zphGw",
	"containerChartTotalCommission": "styles__containerChartTotalCommission___LgqrS"
};
export default ___CSS_LOADER_EXPORT___;
