import styles from './styles.scss';

function RocketSpacePussy() {
  return (
    <div>
      <svg
        width="89"
        height="110"
        viewBox="0 0 89 110"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M34 11.0848C43.7587 -4.43926 45.2397 -3.08906 55 11.5847L57 77.0848H32L34 11.0848Z"
          fill="url(#paint0_linear_1_895)"
        />
        <ellipse
          // className={styles.arrowSpacePussy}
          cx="44.5"
          cy="76.5"
          rx="12.5"
          ry="8.5"
          fill="white"
        />
        <path
          d="M33 64.7854C33 71.7148 28.251 77.3322 22.3929 77.3322C16.5348 77.3322 13 72.2026 13 65.2732C13 60.9458 17.1282 52.3322 23.7042 52.3322C29.5624 52.3322 33 57.8561 33 64.7854Z"
          fill="url(#paint1_radial_1_895)"
        />
        <g filter="url(#filter0_d_1_895)">
          <ellipse
            // className={styles.arrowSpacePussy}
            cx="22.5"
            cy="71.5848"
            rx="7.5"
            ry="5.5"
            fill="white"
          />
        </g>
        <path
          d="M56 64.7854C56 71.7148 60.749 77.3322 66.6071 77.3322C72.4652 77.3322 76 72.2026 76 65.2732C76 60.9458 71.8718 52.3322 65.2958 52.3322C59.4376 52.3322 56 57.8561 56 64.7854Z"
          fill="url(#paint2_radial_1_895)"
        />
        <g filter="url(#filter1_d_1_895)">
          <ellipse
            // className={styles.arrowSpacePussy}
            rx="7.5"
            ry="5.5"
            transform="matrix(-1 0 0 1 66.5 71.5848)"
            fill="white"
          />
        </g>
        <rect
          opacity="0.5"
          x="15"
          y="71.3322"
          width="15"
          height="33"
          fill="url(#paint3_linear_1_895)"
          className={styles.arrowSpacePussy}
        />
        <rect
          opacity="0.5"
          x="59"
          y="71.3322"
          width="15"
          height="33"
          fill="url(#paint4_linear_1_895)"
          className={styles.arrowSpacePussy}
        />
        <rect
          opacity="0.5"
          x="32"
          y="76.3322"
          width="25"
          height="33"
          fill="url(#paint5_linear_1_895)"
          className={styles.arrowSpacePussy}
        />
        <defs>
          <filter
            id="filter0_d_1_895"
            x="0"
            y="51.0848"
            width="45"
            height="41"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="7.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1_895"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1_895"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_1_895"
            x="44"
            y="51.0848"
            width="45"
            height="41"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="7.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1_895"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1_895"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_1_895"
            x1="26.5"
            y1="44.2486"
            x2="63"
            y2="44.2486"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#BE00C5" />
            <stop offset="0.493037" stopColor="#F94DFF" />
            <stop offset="1" stopColor="#AD00B3" />
          </linearGradient>
          <radialGradient
            id="paint1_radial_1_895"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(22.3929 64.7854) rotate(90) scale(12.5467 10.6071)"
          >
            <stop stopColor="#F82EFF" />
            <stop offset="1" stopColor="#B500BB" />
          </radialGradient>
          <radialGradient
            id="paint2_radial_1_895"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(66.6071 64.7854) rotate(90) scale(12.5467 10.6071)"
          >
            <stop stopColor="#F82EFF" />
            <stop offset="1" stopColor="#B900C0" />
          </radialGradient>
          <linearGradient
            id="paint3_linear_1_895"
            x1="22.5"
            y1="71.3322"
            x2="22.5"
            y2="104.332"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_1_895"
            x1="66.5"
            y1="71.3322"
            x2="66.5"
            y2="104.332"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_1_895"
            x1="44.5"
            y1="76.3322"
            x2="44.5"
            y2="109.332"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}

export default RocketSpacePussy;
