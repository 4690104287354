// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchResults-module__search___zFKlI{width:62%;margin:10px auto}.SearchResults-module__search___zFKlI .SearchResults-module__infiniteScroll___k29Wx{margin-top:12px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.SearchResults-module__search___zFKlI .SearchResults-module__infiniteScroll___k29Wx>*{width:100%}@media(max-width: 767px){.SearchResults-module__search___zFKlI{width:90%}}.SearchResults-module__actionBar___YLuGz{position:fixed;left:0;z-index:1}", "",{"version":3,"sources":["webpack://./src/containers/Search/SearchResults.module.scss"],"names":[],"mappings":"AAAA,sCACE,SAAA,CACA,gBAAA,CAEA,oFACE,eAAA,CAEA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAEA,sFACE,UAAA,CAIJ,yBAhBF,sCAiBI,SAAA,CAAA,CAIJ,yCACE,cAAA,CACA,MAAA,CACA,SAAA","sourcesContent":[".search {\n  width: 62%;\n  margin: 10px auto;\n\n  .infiniteScroll {\n    margin-top: 12px;\n\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    > * {\n      width: 100%;\n    }\n  }\n\n  @media (width < 768px) {\n    width: 90%;\n  }\n}\n\n.actionBar {\n  position: fixed;\n  left: 0;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": "SearchResults-module__search___zFKlI",
	"infiniteScroll": "SearchResults-module__infiniteScroll___k29Wx",
	"actionBar": "SearchResults-module__actionBar___YLuGz"
};
export default ___CSS_LOADER_EXPORT___;
