// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InfiniteScrollDataTsx-module__content___yPtw9{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;gap:15px;margin-top:20px}", "",{"version":3,"sources":["webpack://./src/pages/teleport/components/InfiniteScrollDataTxs/InfiniteScrollDataTsx.module.scss"],"names":[],"mappings":"AAAA,+CACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,QAAA,CACA,eAAA","sourcesContent":[".content {\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n  margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "InfiniteScrollDataTsx-module__content___yPtw9"
};
export default ___CSS_LOADER_EXPORT___;
