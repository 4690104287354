// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ActionBar-module__messageInput___xlBdB{-ms-flex-item-align:end;align-self:flex-end}.ActionBar-module__sendBtn___eOHdY{font-size:30px;min-width:100px}.ActionBar-module__error___L9t8n>div{display:inline-block !important}", "",{"version":3,"sources":["webpack://./src/features/sense/ui/ActionBar/ActionBar.module.scss"],"names":[],"mappings":"AAAA,wCACE,uBAAA,CAAA,mBAAA,CAGF,mCACE,cAAA,CACA,eAAA,CAIA,qCACE,+BAAA","sourcesContent":[".messageInput {\n  align-self: flex-end;\n}\n\n.sendBtn {\n  font-size: 30px;\n  min-width: 100px;\n}\n\n.error {\n  > div {\n    display: inline-block !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageInput": "ActionBar-module__messageInput___xlBdB",
	"sendBtn": "ActionBar-module__sendBtn___eOHdY",
	"error": "ActionBar-module__error___L9t8n"
};
export default ___CSS_LOADER_EXPORT___;
