// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notFound-module__container___UKRXP{display:-webkit-box;display:-ms-flexbox;display:flex;width:100%;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;height:100vh;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.notFound-module__container___UKRXP .notFound-module__text___YRa_4{font-size:20px;color:#fff}", "",{"version":3,"sources":["webpack://./src/containers/application/notFound.module.scss"],"names":[],"mappings":"AAAA,oCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,UAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CAEA,mEACE,cAAA,CACA,UAAA","sourcesContent":[".container {\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  flex-direction: column;\n\n  .text {\n    font-size: 20px;\n    color: #fff;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "notFound-module__container___UKRXP",
	"text": "notFound-module__text___YRa_4"
};
export default ___CSS_LOADER_EXPORT___;
