// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Commander-module__wrapper___Nyz_m{width:62%;-webkit-transform:translate(-50%, -80%);transform:translate(-50%, -80%);margin-right:-50%;left:50%;position:absolute;top:50%;padding:0px 20px;z-index:1}.Commander-module__input___zcssz{text-align:center !important;font-size:20px !important}", "",{"version":3,"sources":["webpack://./src/containers/application/Header/Commander/Commander.module.scss"],"names":[],"mappings":"AAAA,mCACE,SAAA,CACA,uCAAA,CAAA,+BAAA,CAEA,iBAAA,CACA,QAAA,CACA,iBAAA,CACA,OAAA,CACA,gBAAA,CACA,SAAA,CAGF,iCACE,4BAAA,CACA,yBAAA","sourcesContent":[".wrapper {\n  width: 62%;\n  transform: translate(-50%, -80%);\n  // background: rgb(0 0 0 / 79%);\n  margin-right: -50%;\n  left: 50%;\n  position: absolute;\n  top: 50%;\n  padding: 0px 20px;\n  z-index: 1;\n}\n\n.input {\n  text-align: center !important;\n  font-size: 20px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Commander-module__wrapper___Nyz_m",
	"input": "Commander-module__input___zcssz"
};
export default ___CSS_LOADER_EXPORT___;
