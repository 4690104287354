// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Mint-module__tabs___tBau1{width:max(300px, min(100%, 380px));margin:0 auto}.Mint-module__containerRcSlider___uQ9b5{display:grid;grid-template-rows:repeat(3, 1fr);justify-items:center;padding:0px 10px;height:100%;width:100%}.Mint-module__containerControl___gdXF9{display:grid;grid-template-columns:250px 1fr 250px;grid-template-rows:auto;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin:64px 8px 0 8px;grid-gap:16px;padding-bottom:150px;position:relative}", "",{"version":3,"sources":["webpack://./src/containers/mint/Mint.module.scss"],"names":[],"mappings":"AAAA,2BACE,kCAAA,CACA,aAAA,CAGF,wCACE,YAAA,CACA,iCAAA,CACA,oBAAA,CACA,gBAAA,CACA,WAAA,CACA,UAAA,CAGF,uCACE,YAAA,CACA,qCAAA,CACA,uBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,qBAAA,CACA,aAAA,CACA,oBAAA,CACA,iBAAA","sourcesContent":[".tabs {\n  width: clamp(300px, 100%, 380px);\n  margin: 0 auto;\n}\n\n.containerRcSlider {\n  display: grid;\n  grid-template-rows: repeat(3, 1fr);\n  justify-items: center;\n  padding: 0px 10px;\n  height: 100%;\n  width: 100%;\n}\n\n.containerControl {\n  display: grid;\n  grid-template-columns: 250px 1fr 250px;\n  grid-template-rows: auto;\n  align-items: center;\n  margin: 64px 8px 0 8px;\n  grid-gap: 16px;\n  padding-bottom: 150px;\n  position: relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": "Mint-module__tabs___tBau1",
	"containerRcSlider": "Mint-module__containerRcSlider___uQ9b5",
	"containerControl": "Mint-module__containerControl___gdXF9"
};
export default ___CSS_LOADER_EXPORT___;
