// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Carousel-module__carousel___OGeo8{width:100%;overflow:hidden;height:40px}.Carousel-module__carousel___OGeo8 .Carousel-module__slidesContainer___BI4Am{height:100%;position:relative}.Carousel-module__carousel___OGeo8 .Carousel-module__slides___v9_3i{height:100%;position:absolute;top:0;left:0;display:-webkit-box;display:-ms-flexbox;display:flex}.Carousel-module__transition___NlNuQ{-webkit-transition:ease all .5s;transition:ease all .5s}", "",{"version":3,"sources":["webpack://./src/components/Tabs/Carousel/Carousel.module.scss"],"names":[],"mappings":"AAAA,mCACE,UAAA,CACA,eAAA,CACA,WAAA,CAEA,6EACE,WAAA,CAEA,iBAAA,CAGF,oEACE,WAAA,CACA,iBAAA,CACA,KAAA,CACA,MAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CAIJ,qCACE,+BAAA,CAAA,uBAAA","sourcesContent":[".carousel {\n  width: 100%;\n  overflow: hidden;\n  height: 40px;\n\n  .slidesContainer {\n    height: 100%;\n    // overflow: hidden;\n    position: relative;\n  }\n\n  .slides {\n    height: 100%;\n    position: absolute;\n    top: 0;\n    left: 0;\n    display: flex;\n  }\n}\n\n.transition {\n  transition: ease all 0.5s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"carousel": "Carousel-module__carousel___OGeo8",
	"slidesContainer": "Carousel-module__slidesContainer___BI4Am",
	"slides": "Carousel-module__slides___v9_3i",
	"transition": "Carousel-module__transition___NlNuQ"
};
export default ___CSS_LOADER_EXPORT___;
