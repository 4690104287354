// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__votesContainer___p5iRK{background-color:rgba(255,255,255,.0784313725);border-radius:5px;overflow:hidden;height:10px;width:100%;display:-webkit-box;display:-ms-flexbox;display:flex}.styles-module__voteSection___baD7J{display:-webkit-box;display:-ms-flexbox;display:flex;height:100%}.styles-module__voteYes___JYqpc{background-color:#3ab793}.styles-module__voteAbstain___prgEY{background-color:#ccdcff}.styles-module__voteNo___MorC0{background-color:#ffcf65}.styles-module__voteNoWithVeto___SCupn{background-color:#fe8a8a}", "",{"version":3,"sources":["webpack://./src/components/governance/styles.module.scss"],"names":[],"mappings":"AAAA,uCACE,8CAAA,CACA,iBAAA,CACA,eAAA,CACA,WAAA,CACA,UAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CAGF,oCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,WAAA,CAGF,gCACE,wBAAA,CAGF,oCACE,wBAAA,CAGF,+BACE,wBAAA,CAGF,uCACE,wBAAA","sourcesContent":[".votesContainer {\n  background-color: #ffffff14;\n  border-radius: 5px;\n  overflow: hidden;\n  height: 10px;\n  width: 100%;\n  display: flex;\n}\n\n.voteSection {\n  display: flex;\n  height: 100%;\n}\n\n.voteYes {\n  background-color: #3ab793;\n}\n\n.voteAbstain {\n  background-color: #ccdcff;\n}\n\n.voteNo {\n  background-color: #ffcf65;\n}\n\n.voteNoWithVeto {\n  background-color: #fe8a8a;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"votesContainer": "styles-module__votesContainer___p5iRK",
	"voteSection": "styles-module__voteSection___baD7J",
	"voteYes": "styles-module__voteYes___JYqpc",
	"voteAbstain": "styles-module__voteAbstain___prgEY",
	"voteNo": "styles-module__voteNo___MorC0",
	"voteNoWithVeto": "styles-module__voteNoWithVeto___SCupn"
};
export default ___CSS_LOADER_EXPORT___;
