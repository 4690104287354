// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AboutTeleport-module__container___Ze0jF{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;gap:25px;margin-top:70px}.AboutTeleport-module__title___Yf8pC{color:#ffca4f;text-align:center;font-weight:400;font-size:20px;line-height:36px;letter-spacing:4px}", "",{"version":3,"sources":["webpack://./src/pages/teleport/mainScreen/components/AboutTeleport/AboutTeleport.module.scss"],"names":[],"mappings":"AAAA,yCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,QAAA,CACA,eAAA,CAIF,qCACE,aAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,kBAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 25px;\n  margin-top: 70px;\n  // align-self: center;\n}\n\n.title {\n  color: #FFCA4F;\n  text-align: center;\n  font-weight: 400;\n  font-size: 20px;\n  line-height: 36px;\n  letter-spacing: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AboutTeleport-module__container___Ze0jF",
	"title": "AboutTeleport-module__title___Yf8pC"
};
export default ___CSS_LOADER_EXPORT___;
