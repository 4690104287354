// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".stylesCode__containerPre___wnObA{padding:10px;background:rgba(119,119,119,.1607843137);border:1px solid #979797}.stylesCode__containerPreCode___qEENI{font-family:monospace !important}.stylesCode__containerPreCode___qEENI{white-space:pre-line}", "",{"version":3,"sources":["webpack://./src/features/ipfs/ipfsSettings/ipfsComponents/stylesCode.scss"],"names":[],"mappings":"AAAA,kCACE,YAAA,CACA,wCAAA,CACA,wBAAA,CAEA,sCACE,gCACA,CAFF,sCAEE,oBAAA","sourcesContent":[".containerPre {\n  padding: 10px;\n  background: #77777729;\n  border: 1px solid #979797;\n\n  &Code {\n    font-family: monospace !important;\n    white-space: pre-line;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerPre": "stylesCode__containerPre___wnObA",
	"containerPreCode": "stylesCode__containerPreCode___qEENI"
};
export default ___CSS_LOADER_EXPORT___;
