// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SettingsMenu-module__wrapper___lZFtA{width:200px}.SettingsMenu-module__wrapper___lZFtA .SettingsMenu-module__links___DOxb6{display:grid;grid-gap:25px;gap:25px}.SettingsMenu-module__wrapper___lZFtA a{display:-webkit-box;display:-ms-flexbox;display:flex;gap:15px;line-height:32px}.SettingsMenu-module__wrapper___lZFtA a .SettingsMenu-module__text___LDaw_{color:var(--grayscale-primary)}.SettingsMenu-module__wrapper___lZFtA a:hover .SettingsMenu-module__text___LDaw_,.SettingsMenu-module__wrapper___lZFtA a.SettingsMenu-module__active___Y2fgg .SettingsMenu-module__text___LDaw_{color:var(--primary-color)}", "",{"version":3,"sources":["webpack://./src/pages/Settings/Layout/SettingsMenu/SettingsMenu.module.scss"],"names":[],"mappings":"AAAA,sCACE,WAAA,CAEA,0EACE,YAAA,CACA,aAAA,CAAA,QAAA,CAGF,wCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,QAAA,CACA,gBAAA,CAEA,2EACE,8BAAA,CAKA,gMACE,0BAAA","sourcesContent":[".wrapper {\n  width: 200px;\n\n  .links {\n    display: grid;\n    gap: 25px;\n  }\n\n  a {\n    display: flex;\n    gap: 15px;\n    line-height: 32px;\n\n    .text {\n      color: var(--grayscale-primary);\n    }\n\n    &:hover,\n    &.active {\n      .text {\n        color: var(--primary-color);\n      }\n\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "SettingsMenu-module__wrapper___lZFtA",
	"links": "SettingsMenu-module__links___DOxb6",
	"text": "SettingsMenu-module__text___LDaw_",
	"active": "SettingsMenu-module__active___Y2fgg"
};
export default ___CSS_LOADER_EXPORT___;
