// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".stylesContractPage__containerContractPageContainerTitleTitle___kyz9l{font-size:20px}.stylesContractPage__containerContractPageContainerTitleBalance___i9UXL{display:-webkit-inline-box;display:-ms-inline-flexbox;display:inline-flex;font-size:18px}", "",{"version":3,"sources":["webpack://./src/containers/wasm/contract/stylesContractPage.scss"],"names":[],"mappings":"AAGE,sEACE,cAAA,CAGF,wEACE,0BAAA,CAAA,0BAAA,CAAA,mBAAA,CACA,cAAA","sourcesContent":["@import '../globalStyle.scss';\n\n.containerContractPageContainerTitle {\n  &Title {\n    font-size: 20px;\n  }\n\n  &Balance {\n    display: inline-flex;\n    font-size: 18px;\n    // @include border-card;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerContractPageContainerTitleTitle": "stylesContractPage__containerContractPageContainerTitleTitle___kyz9l",
	"containerContractPageContainerTitleBalance": "stylesContractPage__containerContractPageContainerTitleBalance___i9UXL"
};
export default ___CSS_LOADER_EXPORT___;
