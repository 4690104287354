// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".heroes-module__containerAccount___GBp0W{max-width:200px}.heroes-module__containerAccountMoniker___igpQC{text-overflow:ellipsis;overflow:hidden}", "",{"version":3,"sources":["webpack://./src/pages/robot/_refactor/account/tabs/heroes.module.scss"],"names":[],"mappings":"AACA,yCACE,eAAA,CAEA,gDACE,sBAAA,CACA,eAAA","sourcesContent":["\n.containerAccount {\n  max-width: 200px;\n\n  &Moniker {\n    text-overflow: ellipsis;\n    overflow: hidden;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerAccount": "heroes-module__containerAccount___GBp0W",
	"containerAccountMoniker": "heroes-module__containerAccountMoniker___igpQC"
};
export default ___CSS_LOADER_EXPORT___;
