// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[3].use[2]!./libs/index.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--primary-color: #36d6ae;--primary-color-r: 54;--primary-color-g: 214;--primary-color-b: 174;--green-light: #76ff03;--green-2: #7afaa1;--blue: #000aff;--blue-light: #1fcbff;--yellow: #fcf000;--yellow-r: 252;--yellow-g: 240;--yellow-b: 0;--red: #ff5c00;--red-r: 255;--red-g: 92;--red-b: 13;--grayscale-disabled: #616161;--grayscale-dark: #808080;--grayscale-secondary: #c7c7c7;--grayscale-primary: #fff;--text-opacity-2: 0.7;--text-opacity-3: 0.7;--text-opacity-4: 0.38;--pink: #f62bfd}", "",{"version":3,"sources":["webpack://./src/style/index.scss"],"names":[],"mappings":"AAGA,MAEE,wBAAA,CACA,qBAAA,CACA,sBAAA,CACA,sBAAA,CAEA,sBAAA,CACA,kBAAA,CAEA,eAAA,CACA,qBAAA,CAEA,iBAAA,CACA,eAAA,CACA,eAAA,CACA,aAAA,CAEA,cAAA,CACA,YAAA,CACA,WAAA,CACA,WAAA,CAEA,6BAAA,CACA,yBAAA,CACA,8BAAA,CACA,yBAAA,CAEA,qBAAA,CACA,qBAAA,CACA,sBAAA,CAEA,eAAA","sourcesContent":["/* stylelint-disable custom-property-empty-line-before */\n@import url('./libs/index.scss');\n\n:root {\n  // greens\n  --primary-color: #36d6ae;\n  --primary-color-r: 54;\n  --primary-color-g: 214;\n  --primary-color-b: 174;\n\n  --green-light: #76ff03;\n  --green-2: #7afaa1;\n\n  --blue: #000aff;\n  --blue-light: #1fcbff;\n\n  --yellow: #fcf000;\n  --yellow-r: 252;\n  --yellow-g: 240;\n  --yellow-b: 0;\n\n  --red: #ff5c00;\n  --red-r: 255;\n  --red-g: 92;\n  --red-b: 13;\n\n  --grayscale-disabled: #616161;\n  --grayscale-dark: #808080;\n  --grayscale-secondary: #c7c7c7;\n  --grayscale-primary: #fff;\n\n  --text-opacity-2: 0.7;\n  --text-opacity-3: 0.7;\n  --text-opacity-4: 0.38;\n\n  --pink: #f62bfd;\n}\n\n/* stylelint-disable */\n:export {\n  primaryColor: var(--primary-color);\n  greenLightColor: var(--green-light);\n  redColor: var(--red);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryColor": "var(--primary-color)",
	"greenLightColor": "var(--green-light)",
	"redColor": "var(--red)"
};
export default ___CSS_LOADER_EXPORT___;
