// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Spark-module__wrapper___QvDfU{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;margin-bottom:15px;min-width:320px;max-width:680px}.Spark-module__left___VkjVT,.Spark-module__right___TjChR{position:absolute;z-index:1;padding:10px;top:calc(45% - 20px)}.Spark-module__left___VkjVT{right:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:7px 10px;font-size:14px}.Spark-module__right___TjChR{padding-top:15px;left:100%}.Spark-module__searchItem___c1GX7{display:block;width:100%}", "",{"version":3,"sources":["webpack://./src/components/search/Spark/Spark.module.scss"],"names":[],"mappings":"AAAA,+BACE,iBAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,kBAAA,CAEA,eAAA,CACA,eAAA,CAGF,yDAEE,iBAAA,CACA,SAAA,CACA,YAAA,CACA,oBAAA,CAGF,4BACE,UAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,YAAA,CACA,cAAA,CAGF,6BACE,gBAAA,CACA,SAAA,CAGF,kCACE,aAAA,CACA,UAAA","sourcesContent":[".wrapper {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 15px;\n\n  min-width: 320px;\n  max-width: 680px;\n}\n\n.left,\n.right {\n  position: absolute;\n  z-index: 1;\n  padding: 10px;\n  top: calc(45% - (40px / 2));\n}\n\n.left {\n  right: 100%;\n  display: flex;\n  align-items: center;\n  gap: 7px 10px;\n  font-size: 14px;\n}\n\n.right {\n  padding-top: 15px;\n  left: 100%;\n}\n\n.searchItem {\n  display: block;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Spark-module__wrapper___QvDfU",
	"left": "Spark-module__left___VkjVT",
	"right": "Spark-module__right___TjChR",
	"searchItem": "Spark-module__searchItem___c1GX7"
};
export default ___CSS_LOADER_EXPORT___;
