// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TeleportStat-module__container___QnbaW{text-align:center;color:var(--grayscale-dark);font-size:14px;line-height:23px}.TeleportStat-module__container___QnbaW .TeleportStat-module__value___Xu99T{color:var(--primary-color);padding:0 3px}", "",{"version":3,"sources":["webpack://./src/pages/teleport/mainScreen/components/TeleportStat/TeleportStat.module.scss"],"names":[],"mappings":"AAAA,wCACE,iBAAA,CACA,2BAAA,CACA,cAAA,CACA,gBAAA,CAEA,4EACE,0BAAA,CACA,aAAA","sourcesContent":[".container {\n  text-align: center;\n  color: var(--grayscale-dark);\n  font-size: 14px;\n  line-height: 23px;\n\n  .value {\n    color: var(--primary-color);\n    padding: 0 3px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TeleportStat-module__container___QnbaW",
	"value": "TeleportStat-module__value___Xu99T"
};
export default ___CSS_LOADER_EXPORT___;
