// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".metaInfo-module__objectInspector___i4rmx li{background-color:#000 !important}", "",{"version":3,"sources":["webpack://./src/containers/ipfs/components/metaInfo.module.scss"],"names":[],"mappings":"AACE,6CACE,gCAAA","sourcesContent":[".objectInspector {\n  li {\n    background-color: #000 !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"objectInspector": "metaInfo-module__objectInspector___i4rmx"
};
export default ___CSS_LOADER_EXPORT___;
