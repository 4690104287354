// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles__ActionBarContainer___z3mic{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:100%;position:fixed;bottom:0;padding:25px 0;background:-webkit-gradient(linear, left bottom, left top, color-stop(76%, rgba(0, 0, 0, 0.93)), to(rgba(0, 0, 0, 0)));background:linear-gradient(0deg, rgba(0, 0, 0, 0.93) 76%, rgba(0, 0, 0, 0) 100%);z-index:2}.styles__ActionBarContainerContent___bEsV9{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;max-width:1000px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;padding:0 3vw;position:relative}", "",{"version":3,"sources":["webpack://./src/containers/portal/components/ActionBar/styles.scss"],"names":[],"mappings":"AAAA,oCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,UAAA,CACA,cAAA,CACA,QAAA,CACA,cAAA,CACA,sHAAA,CAAA,gFAAA,CACA,SAAA,CAEA,2CACE,kBAAA,CAAA,mBAAA,CAAA,WAAA,CACA,gBAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,6BAAA,CAAA,4BAAA,CAAA,sBAAA,CAAA,kBAAA,CACA,aAAA,CACA,iBAAA","sourcesContent":[".ActionBarContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  position: fixed;\n  bottom: 0;\n  padding: 25px 0;\n  background: linear-gradient(0deg, rgba(0,0,0,0.93) 76%, rgba(0,0,0,0) 100%);\n  z-index: 2;\n\n  &Content {\n    flex-grow: 1;\n    max-width: 1000px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: row;\n    padding: 0 3vw;\n    position: relative;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ActionBarContainer": "styles__ActionBarContainer___z3mic",
	"ActionBarContainerContent": "styles__ActionBarContainerContent___bEsV9"
};
export default ___CSS_LOADER_EXPORT___;
