// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Sigma-module__wrapper___VCclB>div{z-index:1}.Sigma-module__chooser___CAep1{margin-left:15px}.Sigma-module__chooser___CAep1>*{width:380px}.Sigma-module__legacy___KWOOV{color:gray;font-size:14px;position:relative;top:2.5px}.Sigma-module__item___UcwtK{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}.Sigma-module__item___UcwtK+.Sigma-module__item___UcwtK{margin-top:20px}", "",{"version":3,"sources":["webpack://./src/features/cybernet/ui/pages/Sigma/Sigma.module.scss"],"names":[],"mappings":"AACE,mCAEE,SAAA,CAIJ,+BACE,gBAAA,CAEA,iCACE,WAAA,CAIJ,8BACE,UAAA,CACA,cAAA,CAEA,iBAAA,CACA,SAAA,CAGF,4BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CAEA,wDACE,eAAA","sourcesContent":[".wrapper {\n  > div {\n    // for chooser\n    z-index: 1;\n  }\n}\n\n.chooser {\n  margin-left: 15px;\n\n  > * {\n    width: 380px;\n  }\n}\n\n.legacy {\n  color: gray;\n  font-size: 14px;\n\n  position: relative;\n  top: 2.5px;\n}\n\n.item {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  & + .item {\n    margin-top: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Sigma-module__wrapper___VCclB",
	"chooser": "Sigma-module__chooser___CAep1",
	"legacy": "Sigma-module__legacy___KWOOV",
	"item": "Sigma-module__item___UcwtK"
};
export default ___CSS_LOADER_EXPORT___;
