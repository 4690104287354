// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SenseButton-module__senseBtn___mvmDT{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;padding-left:3px;padding:40px 10px;background:-webkit-gradient(linear, left top, right top, color-stop(90%, black), to(rgba(255, 255, 255, 0.5)));background:linear-gradient(to right, black 90%, rgba(255, 255, 255, 0.5) 100%);-webkit-backdrop-filter:blur(15px);backdrop-filter:blur(15px);border-radius:50px}.SenseButton-module__senseBtn___mvmDT>span{font-size:14px;margin-top:5px;color:#fff}.SenseButton-module__senseBtn___mvmDT>span:last-of-type{margin-top:4px;font-size:16px}.SenseButton-module__senseBtn___mvmDT:hover{-webkit-transition:all .3s;transition:all .3s;padding-right:30px;background-color:var(--green-light)}.SenseButton-module__senseBtn___mvmDT:hover span{color:var(--green-light)}", "",{"version":3,"sources":["webpack://./src/features/sense/ui/SenseButton/SenseButton.module.scss"],"names":[],"mappings":"AAAA,sCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,gBAAA,CACA,iBAAA,CACA,8GAAA,CAAA,8EAAA,CAKA,kCAAA,CAAA,0BAAA,CACA,kBAAA,CAEA,2CACE,cAAA,CACA,cAAA,CACA,UAAA,CAEA,wDACE,cAAA,CACA,cAAA,CAIJ,4CACE,0BAAA,CAAA,kBAAA,CACA,kBAAA,CAEA,mCAAA,CACA,iDACE,wBAAA","sourcesContent":[".senseBtn {\n  display: flex;\n  flex-direction: column;\n  padding-left: 3px;\n  padding: 40px 10px;\n  background: linear-gradient(\n    to right,\n    black 90%,\n    rgb(255, 255, 255, 0.5) 100%\n  );\n  backdrop-filter: blur(15px);\n  border-radius: 50px;\n\n  > span {\n    font-size: 14px;\n    margin-top: 5px;\n    color: white;\n\n    &:last-of-type {\n      margin-top: 4px;\n      font-size: 16px;\n    }\n  }\n\n  &:hover {\n    transition: all 0.3s;\n    padding-right: 30px;\n\n    background-color: var(--green-light);\n    span {\n      color: var(--green-light);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"senseBtn": "SenseButton-module__senseBtn___mvmDT"
};
export default ___CSS_LOADER_EXPORT___;
