// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".steles__img___rLrEb{width:100%;padding-top:10px}", "",{"version":3,"sources":["webpack://./src/components/contentIpfs/component/img/steles.scss"],"names":[],"mappings":"AAAA,qBACE,UAAA,CACA,gBAAA","sourcesContent":[".img {\n  width: 100%;\n  padding-top: 10px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"img": "steles__img___rLrEb"
};
export default ___CSS_LOADER_EXPORT___;
