// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index-module__buttonIcon___x1Gmy{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border:none;cursor:pointer;background:rgba(0,0,0,0);position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%)}.index-module__buttonIcon___x1Gmy:hover{opacity:.7}.index-module__buttonIcon___x1Gmy img{width:40px;height:55px;-o-object-fit:cover;object-fit:cover}", "",{"version":3,"sources":["webpack://./src/components/ButtonSwap/index.module.scss"],"names":[],"mappings":"AAAA,kCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,WAAA,CACA,cAAA,CACA,wBAAA,CACA,iBAAA,CACA,OAAA,CACA,QAAA,CACA,uCAAA,CAAA,+BAAA,CAEA,wCACE,UAAA,CAGF,sCACE,UAAA,CACA,WAAA,CACA,mBAAA,CAAA,gBAAA","sourcesContent":[".buttonIcon {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border: none;\n  cursor: pointer;\n  background: transparent;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n\n  &:hover {\n    opacity: 0.7;\n  }\n\n  img {\n    width: 40px;\n    height: 55px;\n    object-fit: cover;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonIcon": "index-module__buttonIcon___x1Gmy"
};
export default ___CSS_LOADER_EXPORT___;
