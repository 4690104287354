// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles__containerNextUnfreeze___yWHki{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;gap:5px}.styles__containerItemRow___lfsQN{display:-webkit-box;display:-ms-flexbox;display:flex;width:100%;line-height:20px;font-size:18px;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}.styles__colorTextStatusTime___K7eLK{color:#fff}.styles__colorTextStatusTimeUnfreeze___ikkHa{color:#ff9100}.styles__colorTextStatusTimeReady___MD5Jv{color:#3ab793}", "",{"version":3,"sources":["webpack://./src/containers/portal/components/nextUnfreeze/styles.scss"],"names":[],"mappings":"AAAA,uCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,OAAA,CAGF,kCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,UAAA,CACA,gBAAA,CACA,cAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CAGF,qCACE,UAAA,CAEA,6CACE,aAAA,CAGF,0CACE,aAAA","sourcesContent":[".containerNextUnfreeze {\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n}\n\n.containerItemRow {\n  display: flex;\n  width: 100%;\n  line-height: 20px;\n  font-size: 18px;\n  justify-content: space-between;\n}\n\n.colorTextStatusTime {\n  color: #fff;\n\n  &Unfreeze {\n    color: #ff9100;\n  }\n\n  &Ready {\n    color: #3ab793;\n    // font-weight: 600;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerNextUnfreeze": "styles__containerNextUnfreeze___yWHki",
	"containerItemRow": "styles__containerItemRow___lfsQN",
	"colorTextStatusTime": "styles__colorTextStatusTime___K7eLK",
	"colorTextStatusTimeUnfreeze": "styles__colorTextStatusTimeUnfreeze___ikkHa",
	"colorTextStatusTimeReady": "styles__colorTextStatusTimeReady___MD5Jv"
};
export default ___CSS_LOADER_EXPORT___;
