// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Triangle-module__triangle___dT1gK{color:var(--blue-light);width:0;-webkit-transform:rotate(180deg);transform:rotate(180deg);display:inline-block;height:0;border-left:5px solid rgba(0,0,0,0);border-right:5px solid rgba(0,0,0,0);border-bottom:9px solid var(--blue-light)}.Triangle-module__triangle_up___q3lCg{-webkit-transform:rotate(0deg);transform:rotate(0deg)}.Triangle-module__triangle___dT1gK.Triangle-module__disabled___JK1MJ{color:rgba(255,255,255,.5);border-bottom-color:rgba(255,255,255,.5)}", "",{"version":3,"sources":["webpack://./src/components/atoms/Triangle/Triangle.module.scss"],"names":[],"mappings":"AAAA,mCACE,uBAAA,CACA,OAAA,CAYA,gCAAA,CAAA,wBAAA,CACA,oBAAA,CACA,QAAA,CACA,mCAAA,CACA,oCAAA,CACA,yCAAA,CAEA,sCACE,8BAAA,CAAA,sBAAA,CAGF,qEACE,0BAAA,CACA,wCAAA","sourcesContent":[".triangle {\n  color: var(--blue-light);\n  width: 0;\n\n  // &::before {\n  //   content: '';\n  //   filter: blur(10px);\n  //   position: absolute;\n  //   top: 0;\n  //   left: 0;\n  //   width: 100%;\n  //   height: 100%;\n  //   z-index: -1;\n  // }\n  transform: rotate(180deg);\n  display: inline-block;\n  height: 0;\n  border-left: 5px solid transparent;\n  border-right: 5px solid transparent;\n  border-bottom: 9px solid var(--blue-light);\n\n  &_up {\n    transform: rotate(0deg);\n  }\n\n  &.disabled {\n    color: rgba(255, 255, 255, 0.5);\n    border-bottom-color: rgba(255, 255, 255, 0.5);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"triangle": "Triangle-module__triangle___dT1gK",
	"triangle_up": "Triangle-module__triangle_up___q3lCg",
	"disabled": "Triangle-module__disabled___JK1MJ"
};
export default ___CSS_LOADER_EXPORT___;
