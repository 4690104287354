// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles__containerContent___jnFa1{width:62%;margin:0px auto;display:grid;grid-gap:20px;gap:20px}@media(max-width: 650px){.styles__containerContent___jnFa1{width:82%}}@media(max-width: 500px){.styles__containerContent___jnFa1{width:100% !important}}", "",{"version":3,"sources":["webpack://./src/containers/portal/components/mainContainer/styles.scss"],"names":[],"mappings":"AAAA,kCACE,SAAA,CACA,eAAA,CACA,YAAA,CACA,aAAA,CAAA,QAAA,CAEA,yBANF,kCAOI,SAAA,CAAA,CAGD,yBAVH,kCAWI,qBAAA,CAAA","sourcesContent":[".containerContent {\n  width: 62%;\n  margin: 0px auto;\n  display: grid;\n  gap: 20px;\n\n  @media (max-width: 650px) {\n    width: 82%;\n  }\n\n   @media (max-width: 500px) {\n    width: 100% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerContent": "styles__containerContent___jnFa1"
};
export default ___CSS_LOADER_EXPORT___;
